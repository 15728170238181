import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getBroadcastsList=(callback) =>{
    let path=ApiUrl+"broadcasts/list/"

      axiosClient.get(path)
      .then( (response)=> {
        console.log('getBroadcastsList response', response.data)
        callback(response.data)
      })
      .catch( (error)=> {
        console.log('getBroadcastsList error', error)
        callback({error:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });

  }

  export const getBroadcastsTokens=(broadcastUUID, callback) =>{
    let path=ApiUrl+"broadcasts/token/"+broadcastUUID+"/"

      axiosClient.get(path)
      .then( (response)=> {
        console.log('getBroadcastsTokens response', response.data)
        callback(response.data)
      })
      .catch( (error)=> {
        console.log('getBroadcastsTokens error', error)
        callback({error:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
  }

  export const getBroadcastscam2camToken=(channelName, callback) =>{
    let path=ApiUrl+"broadcasts/cam2cam/"+channelName+"/"

      axiosClient.get(path)
      .then( (response)=> {
        console.log('getBroadcastscam2camToken response', response.data)
        callback(response.data)
      })
      .catch( (error)=> {
        console.log('getBroadcastscam2camToken error', error)
        callback({error:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
  }