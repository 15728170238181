import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'


export const getModelOfTheWeek=(callBack) =>{
    let path=ApiUrl+"modeloftheweek/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getModelOfTheWeek response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getModelOfTheWeek error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const setModelOfTheWeek=(fd, callBack) =>{
    let path=ApiUrl+"modeloftheweek/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('setModelOfTheWeek response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('setModelOfTheWeek error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const semdMOWMailing=(callBack) =>{
    let path=ApiUrl+"sendmowmailing/"
    return dispatch =>{
      axiosClient.post(path)
      .then( (response)=> {
        console.log('semdMOWMailing response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('semdMOWMailing error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }
