import React from 'react'
import { Route } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
//import logo from '../../static/logos/logoWhite4.png';
import { Redirect } from 'react-router'
import Menu from 'react-burger-menu/lib/menus/slide'
import Form from 'react-bootstrap/Form';
import { BsThreeDots } from "react-icons/bs";
import {
  BiMenu,
} from "react-icons/bi";

const mainContainer = React.createRef();
const DefaultLayout = ({ component: Component, ...rest }) => {

  var isMenuOpen = function (state) {
    rest.handleBurgerStateChange(state)
    return state.isOpen;
  };
  let width = window.innerWidth
  let height = window.innerHeight
  height = height - 50
  window.addEventListener("resize", () => { width = window.innerWidth });
  return (
    <Route {...rest} render={matchProps => (

      <Container style={{ minHeight: height }} fluid className="px-0 flex-grow d-flex flex-row align-self-stretch align-items-stretch justify-content-start" >
        <Menu
          disableAutoFocus
          isOpen={rest.menuOpen}
          //onBurgerStateChange={(state) =>rest.handleBurgerStateChange(state)}
          onStateChange={isMenuOpen}
          //customBurgerIcon={<span></span>} 
          width={270}
          activeKey={rest.location ? rest.location.pathname : null}
          right={width < 992 ? true : false}
          left={width >= 992 ? true : false}
        >
          {rest.loggedIn &&
            <Link to="/" href="/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Dashboard
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/streamlist/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Kamerki
            </Link>
          }

          {rest.loggedIn &&
            <Link to="/verifications" href="/verifications" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Weryfikacje
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/observed" href="/observed" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Obserwowane
            </Link>
          }

          {rest.loggedIn &&
            <Link to="/refundacje" href="/refundacje" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Refundacje
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/transakcje/" href="/transakcje/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Transakcje
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/payoutdetails/" href="/payoutdetails/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Wyplaty
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/alerts" href="/alerts" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Alerty/mailing
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/modeloftheweek" href="/modeloftheweek" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Modelka tygodnia
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/chaty" href="/chaty" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Chaty
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/ksiegowosc/podsumowaniemiesiaca/" href="/chaty" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Księgowość
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/marketingagreements/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Zgody Marketingowe
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/userssearch/" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Lista userów
            </Link>
          }
          {rest.loggedIn &&
            <Link to="/promocommision" href="/promocommision" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>
              Promocja prowizji
            </Link>
          }
          {!rest.loggedIn ?
            <Link to="/login" onClick={() => { rest.handleBurgerStateChange({ isOpen: false }) }}>Login</Link>
            :
            <Link to="#" onClick={() => { rest.logout(); rest.handleBurgerStateChange({ isOpen: false }); }}>Log out</Link>
          }
        </Menu>

        <Navbar collapseOnSelect bg="dark" expand="lg" fixed={'top'} className=" navBarBg navbar-dark" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <Navbar.Brand >
            <BiMenu
              className='d-none d-lg-inline bMenuBtn'
              style={{ marginRight: '15px' }}
              onClick={() => { rest.handleBurgerStateChange({ isOpen: true }) }}
            />
            <Link to={'/'}>
              WMM ADMIN
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav d-none d-lg-block">
            <Nav className="mr-auto" activeKey={rest.location ? rest.location.pathname : null} style={{ textTransform: 'capitalize' }}>

              {!rest.loggedIn &&
                <Nav.Link as={Link} to="/login" href="/login">Login</Nav.Link>
              }
              <Nav.Item>
                <BsThreeDots className='cursor menuDots'
                  onClick={() => { rest.handleBurgerStateChange({ isOpen: !rest.menuOpen }) }}
                />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <BiMenu
            className='d-lg-none bMenuBtn'
            onClick={() => { rest.handleBurgerStateChange({ isOpen: true }) }}
          />
        </Navbar>
        <Container id="masterContainer" className=" px-0 pb-5 d-flex align-self-stretch flex-row layoutContainer align-items-stretch justify-content-start" ref={mainContainer} >

          {!rest.loginCompleted &&
            <div></div>
          }
          {rest.loginCompleted &&
            <Component {...matchProps} mainContainer={mainContainer} userBanned={rest.userBanned} />
          }

        </Container>
      </Container>
    )} />
  )
};

export default DefaultLayout