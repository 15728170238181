
import axios from 'axios';
import {getDashboardData,} from './index'
export const DEBUG=false
let axiosAuthToken=null
//PRODUKCYJNY
export const Host='wss://api.watchmemore.com' //uzywany w do tworzenia ws url w chacie
export const ApiUrl='https://api.watchmemore.com/adminapi/'
export const HostUrl='https://api.watchmemore.com/'



//REMOTE DEV 
//export const Host='wss://watchmemoreapi-watchmemore-apiv4test.azurewebsites.net/' 
//export const ApiUrl='https://watchmemoreapi-watchmemore-apiv4test.azurewebsites.net/adminapi/'
//export const HostUrl='https://watchmemoreapi-watchmemore-apiv4test.azurewebsites.net/'
//export const FrontUrl='https://watchmetestfront-watchmedevfront.azurewebsites.net'

//export const MainApiUrl='https://watchmemoreapi-api4-python39.azurewebsites.net/api4/'
//export const ApiUrl='https://watchmemoreapi-api4-python39.azurewebsites.net/adminapi/'
//export const HostUrl='https://watchmemoreapi-api4-python39.azurewebsites.net/'


//LOCAL

//export const MainApiUrl='http://localhost/api4/'
//export const ApiUrl='http://localhost/adminapi/'
//export const HostUrl='http://localhost/'


export const axiosClient = axios.create();

export const upssssText='Upppsss... coś poszło nie tak! Spróbuj jeszcze raz lub skontaktuj się z administratorem serwisu.';

export const makeAxiosInctace=()=> {
  let axiosInstance = axios.create({
    baseURL: ApiUrl,
    timeout: 1000,
    headers: {
      'Authorization': axiosAuthToken,
    }
  });
  return axiosInstance
  axiosInstance=initAxiosInstance(axiosInstance)
  
}

export const setAxiosLocaleHeader=(newLocale)=>{
  axiosClient.defaults.headers['Accept-Language']=newLocale
}

const initAxiosInstance=(instance)=>{
  return dispatch => {
    instance.interceptors.response.use( (response)=> {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, (error) =>{
      if(error.response){
        if(error.response.status && error.response.status>=500){
          console.log('jest status 500', error.response)
         alert('internal server error: '+error.response.data)
          dispatch(
            {type: "WARNING_MODAL_SHOW", 
            text: upssssText+' Id: err-int-x500',
            title:'',
          });
        }
        if(error.response.status && error.response.status>=400){

        }
        if(error.response.status && error.response.status===403){
         // console.log('interceptors bledny token lub bledny endpoint')
          //tu mozemy wywalic modal z informacja z zostales wylogowany
          //dispatch(userLoggedOut()) 
        }
        if(error.response.status && error.response.status===401){
        //  console.log('interceptors niezalogowany')
        }
      }
      return Promise.reject(error);
    });
    return instance
  }
}

export const initAxios=()=>{
    return dispatch => {
      axiosClient.interceptors.response.use( (response)=> {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, (error) =>{
        console.log('interceptors error', error)
        if(error.response){
          if(error.response.status && error.response.status>=500){
          //  console.log('jest status 500')
            alert('internal server error: '+error.response.data)
          //  dispatch(
         //     {type: "WARNING_MODAL_SHOW", 
         //     text: upssssText+' Id: err-int-x500',
         //     title:'',
        //    });
          }
          if(error.response.status && error.response.status>=400){
          }
          if(error.response.status && error.response.status===403){
          //  console.log('interceptors bledny token lub bledny endpoint')
            //tu mozemy wywalic modal z informacja z zostales wylogowany
            dispatch(userLoggedOut()) 
          }
          if(error.response.status && error.response.status===401){
         //   console.log('interceptors niezalogowany')
          }
        }
        return Promise.reject(error);
      });
    }
  }

  export const userLoggedIn =(token)=>{
    console.log('userLoggedIn token', token)
    return dispatch => {
      localStorage.setItem("token", token)
        axiosAuthToken=token
        axiosClient.defaults.headers.common['Authorization'] = 'token '+token
      //  dispatch({ type: "RESET_USER_FEED_DATA", data: true});
      //  dispatch({ type: "RESET_MAIN_FEED_DATA", data: true});
        dispatch({ type: "RESET_ALL"});
        dispatch({ type: 'SET_AUTH_TOKEN', data: token})
        //const reloadEvent = new Event('reload');
        dispatch({type:'SET_LOGGED_IN', data:true})
        dispatch({type:'SET_LOGIN_COMPLETED', data:true})
        dispatch(getDashboardData())
        //setTimeout(()=> window.dispatchEvent(reloadEvent), 300)
    }
  } 
  export const userLoginFetch = (payLoad, callback) => {
    var data = new FormData();
    console.log('payLoad', payLoad)
   // data.append( "email", payLoad.email );
    data.append( "email", payLoad.email );
   // data.append( "username", payLoad.email );
    data.append( "password", payLoad.password );
    return dispatch => {
      let path=HostUrl+"api4/user/login/"
      console.log('path', path)
        //dispatch({ type: 'fetching', data: true})
        axiosClient.post(path, data)
        .then((response)=> {
          console.log('userLoginFetch', response.data)  
          dispatch(userLoggedIn(response.data.key))
        })
        .catch( (error) =>{
          if(error.response){
           // console.log('userLoginFetch error', error.response.data)
            if(error.response.status===400){
              //wyswietlamy bledy w formie
              callback({error:true, data:error.response.data})
              
            }
            else{
              callback({error:true, data:null})
            }
          }
        })
        .then(function () {
          // always executed
          //dispatch({ type: 'fetching', data: false})
        });
      };
    }
  export const userLoggedOut =()=>{
    console.log('userLoggedOut')
    axiosClient.defaults.headers.common={}
    return dispatch => {
      console.log('userLoggedOut')
      localStorage.removeItem("token")
      axiosClient.defaults.headers.common={}
      dispatch({ type: "RESET_ALL"});
      dispatch({type:'SET_LOGIN_COMPLETED', data:true})
      dispatch({type:'WS_DISCONNECT'})
      const reloadEvent = new Event('reload');
      window.dispatchEvent(reloadEvent);
    }
  }

  export const logout = payLoad => {
    var data = new FormData();
    //axiosClient.defaults.headers.common={}
    return dispatch => {
      axiosClient.defaults.headers.common={}
      dispatch({ type: "RESET_USER_FEED_DATA", data: true});
      dispatch({ type: "RESET_MAIN_FEED_DATA", data: true});
      dispatch({ type: 'SET_AUTH_TOKEN', data:null})
      let path=HostUrl+"api4/user/logout/"
      localStorage.removeItem("token")
        //dispatch({ type: 'mainLoader', data: true})
        axiosClient.post(path, data)
        .then((response) =>{
        //  console.log(response)
          
          dispatch(userLoggedOut())
        })
        .catch( (error)=> {
          console.log('error',error)
        })
        .then( ()=> {
          // always executed
          //dispatch({ type: 'mainLoader', data: false})
        });
      };
    }








            