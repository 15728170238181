import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Oval } from 'react-loader-spinner'

import AgoraRTC from "agora-rtc-sdk-ng"
import {
    getBroadcastscam2camToken,
} from "../../actions/index";
const isMobile = false
export default function Cam2camPlayer(props) {
    const dispatch = useDispatch()
    const client = useRef();
    const rtcp= useRef({});
   // const [rtcp, setRtcp] = useState({})
    const playerRef = useRef();
    const remoteTracks = useRef({});
    const connectionStateRef = useRef('INITIAL')
    
    const [playerConfig, setPlayerConfig] = useState({})
    const [connectionState, setConnectionState] = useState('INITIAL')
    const [tokenFetchng, setTokenFetchng] = useState(false)

    const [audioMuted, setAudioMuted] = useState()
    const [videoMuted, setVideoMuted] = useState()
    const [playerId, setPlayerId] = useState((Math.random() + 1).toString(36).substring(7))

    useEffect(() => {
        fetchToken()
    }, [])

    const fetchToken=()=>{
        getBroadcastscam2camToken(props.channelName, tokenFetched)
    }

    const tokenFetched=(data)=>{
        if(!data.error){
            rtcp.current =data
            start()
        }
        else{
            console.log('tokenFetched', data)
        }


    }

    const start = async () => {
        let codecks = await AgoraRTC.getSupportedCodec()
        codecks = codecks.video
        let codec = "h264"
        if (codecks.includes("h264")) {
            codec = "h264"
        }
        else if (codecks.includes("vp9")) {
            codec = "vp9"
        }
        else if (codecks.includes("vp8")) {
            codec = "vp8"
        }
        client.current = AgoraRTC.createClient({ mode: "live", codec: codec })
        client.current.setClientRole('audience');
        client.current.on("connection-state-change", (data) => {
            console.log('cam2cam client.on  connection-state-change: ', data)
            if (connectionStateRef.current === 'CONNECTED' && data === 'DISCONNECTED') {
                //props.onHide()
            }
            setConnectionState(data)
            connectionStateRef.current = data
        });

        client.current.on("token-privilege-will-expire", () => {
            console.log('cam2cam token-privilege-will-expire client', client)
            if (!tokenFetchng) {
                connect()
            }
        });
        client.current.on("token-privilege-did-expire", () => {
            console.log('cam2cam token-privilege-did-expire')
            //wylaczamy player
        });
        connect()
    }
    const connect = async () => {
        // setTokenFetchng(true)
        //    dispatch(getBroadcastsTokens(props.broadcastId, tokenFetched))
        await client.current.leave()

        client.current.join(rtcp.current.appid, rtcp.current.channel, rtcp.current.token, rtcp.current.uid || null)
            .then((uid) => {
                // console.log('joined', uid)
                client.current.on("user-published", handleUserPublished);
                client.current.on("user-unpublished", handleUserUnpublished);
            }).catch((err) => {
                console.error(err)
            });
    }

    const handleUserPublished = (user, mediaType) => {
        //   console.log('handleUserPublished')
        subscribe(user, mediaType);
    }

    const handleUserUnpublished = (user) => {
        //  console.log('handleUserUnpublished user', user)
        //  console.log('handleUserUnpublished trackId', this.props.cameraTrack.trackId)
        // console.log('handleUserUnpublished user._video_added_)', user._video_added_)
        if (!user._video_added_) {
            leave()
        }
    }

    const subscribe = async (user, mediaType) => {
        console.log('subscribe user, mediaType', user, mediaType)
        const uid = user.uid;
        await client.current.subscribe(user, mediaType);
       // let _remoteTracks = Object.assign({}, remoteTracks)
        let _remoteTracks ={}
        if (mediaType === 'video') {
            _remoteTracks.videoTrack = user.videoTrack
            await user.videoTrack.play("cam2camPlayer");
            
        }
        if (mediaType === 'audio') {
            _remoteTracks.audioTrack = user.audioTrack
            await user.audioTrack.play();
        }
        else {
            setAudioMuted(true)
        }
        remoteTracks.current = _remoteTracks
        
    }
    const leave = async () => {
        await client.current.leave();
        //  props.onHide()
    }

    const muteAudio = async (check = true) => {
        if (!remoteTracks.current.audioTrack) {
            return
        }

        try {
            await remoteTracks.current.audioTrack.setMuted(true);
            if (this.state.remoteTracks.audioTrack) {
                await remoteTracks.current.audioTrack.setVolume(0);
            }
            setAudioMuted(true)
        } catch (e) {
            console.log(e)
        }
    }

    const unmuteAudio = async () => {
        if (!remoteTracks.current.audioTrack) {
            return
        }
        try {
            await remoteTracks.current.audioTrack.setVolume(100);
            setAudioMuted(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='cam2camWrapper'>
        <div id={"cam2camPlayer"}
            ref={playerRef}
            className={"player "}
            style={{ width: '100%', height: '100%', position: 'relative' }}
        >
            {connectionState === 'CONNECTING' &&
                    <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
            }
        </div>
        </div>
    )

}