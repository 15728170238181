
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getModelOfTheWeek, setModelOfTheWeek, semdMOWMailing } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../components/dialogToast";
import { genders } from '../constants/common'
class ModelOfTheWeekView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getModelOfTheWeek(this.currentModelFetched)


  }

  componentWillUnmount() {
  }

  state = {
  }
  renderToastContent = (t, msg) => {
    return (
      <div style={{ paddingRight: '25px' }}>
        {msg}
        <div onClick={() => { toast.dismiss(t.id) }}
          style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
        >
          X
        </div>
      </div>
    )
  }

  currentModelFetched = (data) => {
    console.log('currentModelFetched', data)
    if (!data.error) {
      this.setState({ currentModel: data })
    }
    else {
      if (data.data && data.data.message) {
        toast.error((t) => { return this.renderToastContent(t, data.data.message) })
      }
    }

    //toast.success((t) => { return this.renderToastContent(t, data.message) })
    //toast.error((t) => { return this.renderToastContent(t, data.data) })
  }

  searchResultsOnClick = (item) => {
    this.setState({ modelPropsal: item })
    console.log('searchReasoutsOnClick', item)
  }

  handleConfirmBtn = (confirmed = false) => {
    toast.dismiss();
    if (!confirmed) {
      DialogToast('Czy napewno chcesz ustawić ' + this.state.modelPropsal.displayName + ' jako modelkę tygodnia?', () => this.handleConfirmBtn(true))
      return
    }
    this.setState({ fetching: true })
    const fd = new FormData()
    fd.append('uid', this.state.modelPropsal.user_id)
    this.props.setModelOfTheWeek(fd, this.modelOfTheWeekSets)
  }

  modelOfTheWeekSets = (data) => {
    console.log('modelOfTheWeekSets', data)
    this.setState({ fetching: false })
    if (!data.error) {
      this.setState({ currentModel: data, modelPropsal: undefined })
    }
    else {
      if (data.data && data.data.message) {
        toast.error((t) => { return this.renderToastContent(t, data.data.message) })
      }
    }

  }
  sendMailing = (confirmed = false)=>{
    toast.dismiss();
    if (!confirmed) {
      DialogToast('Czy napewno chcesz wysłać masowy mailing do wszystkich użytkowników?', () => this.sendMailing(true))
      return
    }
    this.setState({ fetching: true })
    this.props.semdMOWMailing(this.mailingSent)
  }

  mailingSent=(data)=>{
    this.setState({ fetching: false })
    if(!data.error){
      toast.success((t) => { return this.renderToastContent(t, data.message) })
      this.setState({currentModel:data.profile})
    }
  }

  renderCurrentModel = () => {
    if (!this.state.currentModel) {
      return null
    }
    return (
      <Row noGutters>
        <Col xs={12} className="text-center upperCase border-top pt-2 text-faded pb-3">
          <h3>Obecna modelka:</h3>
        </Col>
        <Col xs={12} className="text-center">
          <Image src={this.state.currentModel.avatar} height={110} roundedCircle />
        </Col>
        <Col xs={12} className="text-center upperCase">
          <h4>{this.state.currentModel.displayName} </h4>
          {genders[this.state.currentModel.gender]}
        </Col>
        <Col xs={12} className="text-center upperCase py-2">
          Mailing - {this.state.currentModel.mailing ? "Wysłany" : "Nie wysłany"}
          <br></br>
          {this.state.currentModel.mailing &&
            'Data mailingu - ' + this.state.currentModel.mailingTime
          }
        </Col>
        {!this.state.currentModel.mailing &&
          <Col xs={12} className="text-center upperCase py-2">
            <Button variant="danger"
              size="sm"
  
              onClick={() => this.sendMailing()}

            >
              WYŚLIJ MAILING DO UŻYTKOWNIÓW
            </Button>
          </Col>
        }
        <Col xs={12} className="text-center upperCase py-3">
          <a href={"/user/" + this.state.currentModel.user_id} target='_blank' className="mr-4" style={{ width: '120px' }}>
            zobacz karte
          </a>
          <a href={"https://www.watchmemore.com/" + this.state.currentModel.urlName + "/"} variant="outline-info" size="sm" style={{ width: '120px' }} target='_blank' >
            zobacz w portalu
          </a>
        </Col>

      </Row>
    )
  }


  renderModelProposal = () => {
    if (!this.state.modelPropsal) {
      return null
    }
    return (
      <Row noGutters>
        <Col xs={12} className="text-center upperCase border-top pt-2 text-faded">
          <h3>Proponowana modelka:</h3>
        </Col>
        <Col xs={12} className="text-center">
          <Image src={this.state.modelPropsal.avatar} height={110} roundedCircle />
        </Col>
        <Col xs={12} className="text-center upperCase">
          <h4>{this.state.modelPropsal.displayName} </h4>
          {genders[this.state.modelPropsal.gender]}
        </Col>
        <Col xs={12} className="text-center upperCase py-3">
          <a href={"/user/" + this.state.modelPropsal.user_id} target='_blank' className="mr-4" style={{ width: '120px' }}>
            zobacz karte
          </a>
          <a href={"https://www.watchmemore.com/" + this.state.modelPropsal.urlName + "/"} variant="outline-info" size="sm" style={{ width: '120px' }} target='_blank' >
            zobacz w portalu
          </a>
        </Col>
        <Col xs={12} className="text-center upperCase py-2" >
          <Button variant="outline-success"
            size="sm"
            style={{ width: '120px' }}
            onClick={() => this.handleConfirmBtn()}

          >
            ZATWIERDŹ
          </Button>
        </Col>
        <Col xs={12} className="text-center upperCase py-2" >
          <Button
            variant="outline-danger"
            size="sm"
            style={{ width: '120px' }}
            onClick={() => this.setState({modelPropsal:undefined})}
          >
            ODRZUĆ
          </Button>
        </Col>
      </Row>
    )
  }



  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/login/'} />
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />
    }
    return (
      <Col xs={12} className='p-0'>
        <Toaster />
        <Row noGutters>
          <Col xs={12} className='text-center upperCase py-3'>
            <h3>Modelk tygodnia</h3>
          </Col>
        </Row>
        {this.renderCurrentModel()}
        {this.renderModelProposal()}
        <UserSearchBox
          showAvatar={true}
          onClick={this.searchResultsOnClick}
          isVerified={2}
          gender={1}
        />

        {this.state.fetching &&
          <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
          </Container>
        }


      </Col>
    )
  }

}



function mapStateToProps(state) {
  return {

    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,
    dashboardData: state.dashboard.dashboardData,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getModelOfTheWeek,
        setModelOfTheWeek,
        semdMOWMailing,
      }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelOfTheWeekView);

