import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initAxios, logout, userLoggedOut, userLoggedIn, } from "./actions/index";
import { Oval } from 'react-loader-spinner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './logo.svg';
import './App.css';
import DefaultLayout from './views/layouts/layout'
import DashboardView from './views/dashboardView'
import VerificationListView from './views/verificationListView'
import AlertsView from './views/alertsView'
import ZarobkiDetailsView from './views/zarobkiDetailsView'
import DotpayRequestDetails from './views/dotpayRequestDetails'
import Refundacje from './views/refundacje'
import TransakcjeView from './views/transakcjeView'
import PayoutDetailsView from './views/payoutDetailsView'
import ModelOfTheWeekView from './views/modelOfTheWeekView'
import PodsumowanieMiesiacaView from './views/podsumowanieMiesiacaView'
import PromoCommisionView from './views/promoCommisionView';
import ObservedListView from './views/observedListView';
import MarketingAgreementsView from './views/marketingAgreementsView';
import NewPostMailingNots from './views/newPostMailingNots';
import UsersSearch from './views/usersSearch';
import BulkMailingView from './views/bulkMailingView';
import TokenPayoutsView from './views/tokenPayoutsView';
import StreamsListView from './views/streamsListView';
import StreamPlayerWrapper from './views/streamPlayerWrapper';

 
import Chats from './views/chats'
import { Redirect } from "react-router-dom";
import Login from './views/auth/login'

import UserView from './views/userView'

class App extends Component {

  UNSAFE_componentWillMount() {

  }

  componentDidMount() {
    this.props.initAxios()
    //initAxios()
    let token = localStorage.getItem("token")
    if (token) {
      this.props.userLoggedIn(token)
    }
    else {
      this.props.userLoggedOut()
    }
    //  window.addEventListener('storage', e => {
    //    if(e.key === 'token' && e.oldValue && !e.newValue) {
    //       this.props.userLoggedOut()
    //     }
    //    if(e.key === 'token' && !e.oldValue && e.newValue) {
    //     this.props.userLoggedIn(e.newValue)
    //}
    // }); 
  }

  handleBurgerStateChange = (burgerState) => {
    this.setState({ menuOpen: burgerState.isOpen })
  }

  state = {
    menuOpen: false
  }
  renderIntro = () => {
    return (
      <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
      </Container>
    )
  }
  render() {
    console.log(this.props.loginCompleted, this.props.loggedIn)
    if (!this.props.loginCompleted) {
      return (this.renderIntro())
    }
    if (this.props.loginCompleted && !this.props.loggedIn) {
      console.log('return <Login />')
      return <Login />
    }
    return (
      <Router>
        {/* A <Switch> looks through its children <Route>s and
      renders the first one that matches the current URL. */}
        <Switch>
          <DefaultLayout path="/login/"
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            component={Login}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />

          <DefaultLayout path="/verifications"
            component={VerificationListView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/user/:id/"
            component={UserView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/alerts"
            component={AlertsView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/zarobki/details/:id/"
            component={ZarobkiDetailsView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/payoutdetails/"
            component={PayoutDetailsView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/tokenpayouts/"
            component={TokenPayoutsView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />

          <DefaultLayout path="/dotpayRequests/details/:id/"
            component={DotpayRequestDetails}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/refundacje/"
            component={Refundacje}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/transakcje/"
            component={TransakcjeView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/modeloftheweek/"
            component={ModelOfTheWeekView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/chaty/:userId/:chatId/"
            component={Chats}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/chaty/:userId/"
            component={Chats}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/chaty/"
            component={Chats}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/newpostmailing/:postId/"
            component={NewPostMailingNots}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
           <DefaultLayout path="/bulkMailing/"
            component={BulkMailingView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          
          <DefaultLayout path="/ksiegowosc/podsumowaniemiesiaca/"
            component={PodsumowanieMiesiacaView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/promocommision/"
            component={PromoCommisionView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />


          <DefaultLayout path="/observed/"
            component={ObservedListView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />

          <DefaultLayout path="/marketingagreements/"
            component={MarketingAgreementsView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
          <DefaultLayout path="/userssearch/"
            component={UsersSearch}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
      <DefaultLayout path="/streamlist/"
            component={StreamsListView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />
      <DefaultLayout path="/streamplayer/:broadcastUUID/"
            component={StreamPlayerWrapper}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />

  <DefaultLayout path="/"
            component={DashboardView}
            menuOpen={this.state.menuOpen}
            handleBurgerStateChange={this.handleBurgerStateChange}
            loginCompleted={this.props.loginCompleted}
            loggedIn={this.props.loggedIn}
            logout={this.props.logout}
          />

MarketingAgreementsView


        </Switch>
      </Router>
    )
  }

}

function mapStateToProps(state) {
  return {
    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,
    loginCompleted: state.auth.loginCompleted,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ initAxios, logout, userLoggedOut, userLoggedIn }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
