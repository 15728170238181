
import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Oval } from 'react-loader-spinner'
import Image from 'react-bootstrap/Image';
import { genders, profileStatus } from '../../constants/common'
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { AiOutlineDown } from "react-icons/ai";

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

function DjangoAdmin(props) {

    return (
        <Row noGutters>
        <Col xs={12} className='text-center   '>
            <Accordion >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className="border bg-white cursor text-primary"
                >
                    Zaawansowane{<AiOutlineDown />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Row noGutters>
                        <Col xs={12} md={4} className='py-4 px-3 mx-auto'>
                            <Row noGutters>
                                <Col xs={12} className='text-center mx-auto'>
                                    <a href={{ pathname: 'https://api.watchmemore.com/admin/app/profilesmeta/' + props.data.profile.id + '/change/' }} target='_blank'   className="btn btn-redSm w-100">
                                        przejdź do administracji profilu
                                    </a>
                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={12} className='text-center mx-auto'>
                                    <a href={{ pathname: 'https://api.watchmemore.com/admin/auth/user/' + props.data.user.id + '/change/' }} target='_blank'  className="btn btn-redSm w-100">
                                        przejdź do administracji usera
                                    </a>
                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={12} className='text-center mx-auto pt-3'>
                                    <Button variant="redSm" size={'sm'} className='w-100'>
                                        zaloguj się jako użytkownik TO DO
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Collapse>
            </Accordion>
        </Col>
    </Row>
    )
}
export default DjangoAdmin