import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Oval } from 'react-loader-spinner'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { MdOutlineClose } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { FaPhoneSlash } from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { getTokenPayoutList, getTokenPayoutDetails, patchTokenPayoutDetails } from '../actions/index'
import { GiTwoCoins } from 'react-icons/gi'
import toast, { Toaster } from 'react-hot-toast';
import { sepaCountries } from '../constants/sepaCountries'

export default function TokenPayoutsView(props) {
    const dispatch = useDispatch()
    const [listFetching, setListFetching] = useState();
    const [detailsFetching, setSetailsFetching] = useState();
    const [listData, setListData] = useState();
    const [detailsData, setDetailsData] = useState();
    const [iban, setIban] = useState();
    const [ibanCountryCode, setIbanCountryCode] = useState();
    const [showPayoutStatusModal, setShowPayoutStatusModal] = useState();
    const [sendMail, setSendMail] = useState(true);
    const [payoutStatusFetching, setPayoutStatusFetching] = useState();



    // const iban = useRef();
    // const ibanCountryCode = useRef();

    useEffect(() => {
        setListFetching(true)
        getTokenPayoutList('pending', listFetched)
    }, [])

    const listFetched = (data) => {
        console.log('listFetched', data)
        setListFetching(false)
        if (!data.error) {
            setListData(data)
        }
    }

    const getPayoutDetails = (id) => {
        setDetailsData(undefined)
        setIban(undefined)
        //  iban.current=undefined
        setIbanCountryCode(undefined)
        setSetailsFetching(true)
        getTokenPayoutDetails(id, detailsFetched)

    }

    const detailsFetched = (data) => {
        console.log('detailsFetched', data)
        setSetailsFetching(false)
        if (!data.error) {
            setDetailsData(data)
            parseIban(data.payoutData.iban)
        }
    }

    const renderListItem = (item, index) => {
        //bloggerDisplayName, bloggerUrlName, createDime, kwota, waluta,'paused', 'banned'
        return (
            <tr className='cursor' key={'listItem' + index} onClick={() => getPayoutDetails(item.id)}>
                <td>{index + 1}</td>
                <td style={{ fontSize: '0.8em' }} className="text-left">{item.createTime}</td>
                <td style={{ fontSize: '0.8em' }} className="capitalize">{item.blogger.displayName}</td>
                <td style={{ fontSize: '0.8em' }} className="upperCase text-right"><strong>{item.fiatValue}{item.waluta}</strong></td>
                <td style={{ fontSize: '0.8em' }}>  {item.statusVerbal}</td>
            </tr>
        )
    }
    const parseIban = (_iban) => {
        console.log('parseIban', _iban)
        try {
            setIbanCountryCode(_iban.slice(0, 2));
            let ibanNumber = _iban.slice(2, _iban.length);
            console.log('ibanNumber', _iban)
            console.log('ibanCountryCode.current', ibanCountryCode)
            if (ibanCountryCode == 'PL') {
                //  iban.current = ibanNumber
                setIban(ibanNumber)
            }
            else {
                // iban.current = _iban
                setIban(_iban)
            }
            console.log('iban', iban)
        } catch (e) {
            console.log('parseIban error', e)
            setIban('Błąd! Brak nr IBAN')
            setIbanCountryCode('??')
            //iban.current={ _iban: 'Błąd! Brak nr IBAN', _ibanCountryCode: '??' }
        }
        // ibanNumber = this.addSpacesToIban(ibanNumber)
    }
    const updatePayoutStatus = (status) => {
        const fd = new FormData()
        fd.append('status', status)
        fd.append('sendMail', sendMail)
        
        patchTokenPayoutDetails(detailsData.id, fd, statusUpdated)
    }

    const statusUpdated=(data)=>{
        console.log('statusUpdated', data)
        if(!data.error){
            setDetailsData(data)
            let copy = JSON.parse(JSON.stringify(listData)) 
            let index = copy.findIndex(p => p.id == data.id);
            console.log('jest index', index)
            if(index>-1){
                console.log('jest index 2', index) 
                copy.splice(index, 1, data);
                setListData(copy)
                setShowPayoutStatusModal(false)
            }
        }

    }
    const renderPaymentDetails = () => {
        return (
            <Row noGutters>
                <Col xs={12} className='px-2 text-center mt-4 capitalize'>
                    <Link to={'/user/' + detailsData.blogger.id + '/'} target={'_blank'}>
                        <Image src={detailsData.blogger.avatar} roundedCircle height={60} />
                    </Link>
                    <br></br>
                    <Link to={'/user/' + detailsData.blogger.id + '/'} target={'_blank'}>
                        <h5>
                            {detailsData.blogger.displayName}
                        </h5>
                    </Link>
                </Col>

                <Col xs={12} className='px-2 text-center pt-3 upperCase'>
                    KWOTA DO WYPŁATY: <br></br>
                    <h4>{detailsData.fiatValue} {detailsData.currency}</h4>

                </Col>
                <Col xs={12} className='px-2 pt-3 text-center'>
                    DANE
                </Col>
                <Col xs={12} className='px-2  text-center capitalize'>
                    <h4>
                        {detailsData.payoutData.bankAccountName}<br></br>
                        {detailsData.payoutData.bankAccountSurname}
                    </h4>
                </Col>
                {ibanCountryCode !== 'PL' && ibanCountryCode !== undefined &&
                    <>
                        <Col xs={12} className='px-2 pt-3 text-center'>
                            BIC/SWIFT
                        </Col>
                        <Col xs={12} className='px-2 text-center'>
                            <h4>{detailsData.payoutData.BIC}</h4>
                        </Col>
                        <Col xs={12} className='px-2 pt-3 text-center'>
                            KRAJ BANKU
                        </Col>
                        <Col xs={12} className='px-2 text-center'>
                            <h4>{sepaCountries[ibanCountryCode]}</h4>
                        </Col>
                    </>
                }
                <Col xs={12} className='px-2 pt-3 text-center'>
                    NR. KONTA:
                </Col>
                <Col xs={12} className='px-2 text-center'>
                    {console.log('iban.current 2', iban)}
                    {!detailsData.paused &&
                        <h4>{iban}</h4>
                    }
                    {detailsData.paused &&
                        <h4 className="text-danger">WYPŁATA ZABLOKOWANA</h4>
                    }
                </Col>
                <Col xs={12} className='px-2 pt-3 text-center'>
                    TYTUŁEM:
                </Col>
                <Col xs={12} className='px-2 text-center'>
                    <h5>{ibanCountryCode === 'PL' ? 'Rozliczenie twórcy z serwisu Watchmemore. Id:' : 'Settlement of the creator of the Watchmemore service. Id:'}  {detailsData.payoutId}</h5>
                </Col>
                <Col xs={12} className='px-2 mt-3 text-center'>
                    <strong>UTWORZONA:</strong><br></br>
                    {detailsData.createTime}
                </Col>
                <Col xs={12} className='px-2 mt-1 text-center'>
                    <strong>STATUS:</strong><br></br>
                    {detailsData.statusVerbal}
                </Col>
                {detailsData.payoutStatus === 2 &&
                    <Col xs={12} className='px-2 mt-1 text-center'>
                        ZAPAŁACONA: <br></br>
                        {detailsData.payoutDate}
                    </Col>
                }

                {!detailsData.banned &&
                    <>
                        <Col xs={12} className='px-2 mt-3 text-center'>
                            <Button variant={detailsData.payoutStatus === 2 ? 'danger' : 'success'} onClick={() => setShowPayoutStatusModal(true)}>
                                ZMIEŃ STATUS
                                {detailsData.payoutStatus === 1 && ' NA ZAPŁACONA'}
                                {detailsData.payoutStatus === 2 && ' NA NIEZAPŁACONA'}
                            </Button>
                        </Col>
                        {detailsData.payoutStatus === 1 &&
                            <Col xs={12} className='px-2 mt-3 text-center'>
                                {!detailsData.paused &&
                                    <Button variant={'warning'} onClick={() => { this.showPayoutPauseModal('pause') }}>
                                        ZABLOKUJ WYPŁATĘ
                                    </Button>
                                }
                                {detailsData.paused &&
                                    <Button variant={'danger'} onClick={() => { this.showPayoutPauseModal('unpause') }}>
                                        ODBLOKUJ WYPŁATĘ
                                    </Button>
                                }
                            </Col>
                        }
                    </>
                }
            </Row>
        )
    }

    return (
        <Container>
            <Toaster />
            <Row noGutters>
                <Col xs={4} className='border-right payoutsLeftWrapper d-flex align-items-start  flex-column'>
                    <Row noGutters >
                        <Col xs={12} className='text-center upperCase py-3 px-0'>
                            <h5>WYPŁATY ŻETONÓW:</h5>
                        </Col>
                    </Row>

                    <div className='payoutListWrapper px-0'>
                        {listFetching &&
                            <Row noGutters>
                                <Col xs={12} className='text-center mt-5'>
                                    <div className='mx-auto' style={{ width: '125px', height: '125px' }}>
                                        <Oval className='mx-auto' color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Col >
                            <Table striped bordered hover >
                                <tbody>
                                    {listData && listData.length > 0 && listData.map((item, index) => {
                                        return renderListItem(item, index)
                                    })}
                                </tbody>
                            </Table>
                            {/** 
                                <div><strong>SUMA: {this.state.sum}</strong></div>
                            */}
                        </Col>
                    </div>
                </Col>
                <Col xs={8} className=''>
                    {detailsFetching &&
                        <Row noGutters>
                            <Col xs={12} className='text-center mt-5'>
                                <div className='mx-auto' style={{ width: '125px', height: '125px' }}>
                                    <Oval className='mx-auto' color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                                </div>
                            </Col>
                        </Row>
                    }
                    {detailsData &&
                        renderPaymentDetails()
                    }
                </Col>
            </Row>

            <Modal
                centered
                show={showPayoutStatusModal}
                onHide={() => setShowPayoutStatusModal(false)}
                dialogClassName="modal-90h  flex-column"
            >
                <Modal.Body className='p-0  '>
                    {detailsData &&
                        <Row noGutters className=''>
                            <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
                                <h3>STATUS WYPŁATY</h3>
                            </Col>
                            <Col xs={12} className='text-center py-2'>
                                <h5>{detailsData.payoutId}</h5>
                            </Col>
                            {detailsData.paused &&
                                <Col xs={12} className='text-center py-2'>
                                    <h5 className="text-danger">Wypłata jest zablokowana</h5>
                                </Col>
                            }
                            {!detailsData.paused &&
                                <>
                                    <Col xs={12} className='text-center py-2'>
                                        <h5 className="text-danger">Czy napewno chcesz zmienić status wypłaty?</h5>
                                    </Col>
                                    {detailsData.payoutStatus === 1 &&
                                        <Col xs={12} className='text-center py-2'>
                                            <Form.Check
                                                type="checkbox"
                                                checked={sendMail}
                                                label="Wyslij mail"
                                                onChange={(e) => { setSendMail(e.target.checked) }}
                                            />

                                        </Col>
                                    }
                                    <Col xs={12} className='text-center py-2'>
                                        {detailsData.payoutStatus === 1 &&
                                            <Button variant={'success'}
                                                onClick={() => { updatePayoutStatus('paid') }}
                                                disabled={payoutStatusFetching}
                                            >
                                                OZNACZ JAKO ZAPŁACONĄ {payoutStatusFetching && <Spinner size={'sm'} />}
                                            </Button>
                                        }
                                        {detailsData.payoutStatus === 2 &&
                                            <Button variant={'danger'}
                                                onClick={() => { updatePayoutStatus('unpaid') }}
                                                disabled={payoutStatusFetching}
                                            >
                                                OZNACZ JAKO NIEZAPŁACONĄ {payoutStatusFetching && <Spinner size={'sm'} />}
                                            </Button>
                                        }
                                    </Col>
                                </>
                            }
                            <Col xs={12} className='text-center py-2'>

                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal >
        </Container>
    )
}