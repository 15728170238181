
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getBroadcastsList,
} from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";

import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import VerificationPicture from "../components/verificationPicture";
import ImageAnalizeModal from "../components/imageAnalizeModal";
import Header from "../components/userView/header";
import DjangoAdmin from "../components/userView/djangoAdmin";
import { BsClock } from 'react-icons/bs';
import { genders, profileStatus } from '../constants/common'
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
const fakeData = [
    {
        "streams": [
            {
                "user": 1,
                "uuid": "e8429c8b-e174-4d34-82e5-502ef1967ba8",
                "broadcastUUID": "a5ffc1a1-d325-44d0-873c-3d71d626447b",
                "trackId": "track-cam-80fe0963",
                "timeStamp": 1725982179582
            }
        ],
        "broadcastUUID": "a5ffc1a1-d325-44d0-873c-3d71d626447b",
        "bid": 828,
        "mode": "paralel",
        "state": "priv",
        "priv": null,
        "privData": null,
        "privUser": 157,
        "privUserData": {
            "uid": 157,
            "uuid": "ebd48d6d-58dc-40eb-80da-5490d9f2e2f5",
            "displayName": "Jebacpis",
            "avatar": "https://wmmtest.blob.core.windows.net/wmmusersmedia/usr_3lIoSKGV--n2fXhoiZCgCl20jTk/avatar/FaceApp_1643834216884.jpg"
        },
        "privUserTime": 0,
        "privBroadcasterTime": 0,
        "privResolved": false,
        "privEnabled": true,
        "broadcaster": {
            "uid": 1,
            "uuid": "29bf14a8-f355-445a-a1c6-ac8e7e3222d4",
            "displayName": "witek111",
            "avatar": "https://wmmtest.blob.core.windows.net/wmmusersmedia/usr_-vjTWOTO9oSooJiilxbIvkrI3F0/avatar/b3b7e92b-d629-4f4d-a40f-8974e47ae8ee.jpg",
            "videoCallPriceMin": 10,
            "videoCallPrivPriceMin": 10
        }
    }
]

class StreamsListView extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        this.fetchData()
    }

    componentWillUnmount() {
    }

    state = {
        broadcastsData: undefined
    }

    fetchData = () => {
        this.setState({ fetching: true, })
        getBroadcastsList(this.dataFetched)

    }

    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ broadcastsData: data })
          //  this.setState({ broadcastsData: fakeData })
        }
        else {
            console.log(data)
        }
    }

    renderStreamThumb = (item, index) => {
        return (

            <Col xs={6} md={3} key={'streamThumb' + index} className="streamThumb p-2">
                <Link to={'/streamplayer/'+item.broadcastUUID+'/'} target={'_blank'}>
                    <Image src={item.broadcaster.avatar} style={{ width: '100%', height: 'auto' }} />
                </Link>
                <div className='dName'>{item.broadcaster.displayName}</div>
                {item.privUserData &&
                    <div className='pudName'>Priv z {item.privUserData.displayName}</div>
                }
                {item.privUserData &&
                    <div className="privUser">
                        <Link to={'/streamplayer/'+item.broadcastUUID+'/'} target={'_blank'}>
                            <Image src={item.privUserData.avatar} style={{ width: '100%', height: 'auto' }} />
                        </Link>
                    </div>
                }
            </Col>

        )


    }


    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }


        return (
            <Container fluid>
                <Row noGutters>
                    <Col xs={12} className="text-center">
                        <h4>Kamerki</h4>
                    </Col>
                </Row>
                {!this.state.broadcastsData && this.state.fetching &&
                    <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                        <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                        </Container>
                    </div>
                }
                <Row noGutters>
                    {this.state.broadcastsData && this.state.broadcastsData.map((item, index) => {
                        return this.renderStreamThumb(item, index)
                    })}
                </Row>
            </Container>


        )



    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,
        user: state.user.user,
        profile: state.user.profile,
        adminLogs: state.user.adminLogs,
        inWatched: state.user.inWatched,
        declineReasons: state.user.declineReasons,
        nextActions: state.user.nextActions,
        inSuspected: state.user.inSuspected,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {

            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamsListView);

