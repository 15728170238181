
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { newPostMailingPreview } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class NewPostMailingNots extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetch()
    }

    componentWillUnmount() {

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match !== this.props.match) {
            //Perform some operation here
            //   this.setState({ redirectNoPush: false, redirect: false })
            //  this.naviStateChanged()
        }
    }



    state = {
        mode: 'blurred',
        mediaIndex: 0,
        sendTest: true,

    }

    fetch = (send = false) => {
     
        const postId = this.props.match.params.postId
        const fd = new FormData()
        fd.append('mode', this.state.mode)
        fd.append('mediaIndex', this.state.mediaIndex)
        if (send) {
            fd.append('send', true)
            if (this.state.sendTest) {
                fd.append('test', true)
            }
        }
        if (!send) {
            this.setState({ feching: true })
            this.props.newPostMailingPreview(postId, fd, this.dataFetched)
        }
        else {
            let confirmtext = ''
            if (this.state.sendTest) {
                confirmtext = 'Czy napewno chcesz wysłac testowy email do samego siebie?'
            }
            else {
                confirmtext = 'Czy napewno chcesz wysłac ten email do ' + this.state.data.receiversCount + ' użytkowników?'
            }
            if (window.confirm(confirmtext) == true) {
                this.setState({ feching: true })
                this.props.newPostMailingPreview(postId, fd, this.dataFetched)
            } else {
                console.log('Canceled')
            }
        }
    }

    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ feching: false })
        if (!data.error) {
            this.setState({ data: data })
            if(data.sended){
                alert('Wiadomości zostały wysłane')
            }
        }

    }
    modeChange = (mode) => {
        this.setState({ mode: mode }, () => {
            this.fetch()
        })

    }

    handleMediaSelect = (index) => {
        this.setState({ mediaIndex: index }, () => {
            this.fetch()
        })
    }
    renderMedia = () => {
        if (!this.state.data) {
            return null
        }
        let isVideo = false
        let mediaUrl = ''
        let rendered = []
        this.state.data.medias.map((item, index) => {
            if (this.state.mode === 'normal') {
                if (item.filenamemob) {
                    mediaUrl = item.filenamemob
                }
                if (item.videoSpriteA) {
                    mediaUrl = item.videoSpriteA
                    isVideo = true
                }
            }
            if (this.state.mode == 'blurred') {
                if (item.filenameMobBlured) {
                    mediaUrl = item.filenameMobBlured
                }
                if (item.videoSpriteABlured) {
                    mediaUrl = item.videoSpriteABlured
                    isVideo = true
                }
            }
            rendered.push(
                <img src={mediaUrl} style={{ height: '120px', display: 'inline-block', cursor: 'pointer', marginRight: '10px' }} onClick={() => this.handleMediaSelect(index)} />
            )

        })
        return rendered
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Col xs={12} className='p-0'>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <p>Mailing o nowym poscie</p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center pb-2'>
                        <span style={{ marginRight: '15px' }}>
                            Zblurowany:
                        </span>
                        <Form.Check
                            inline
                            label={'Nie'}
                            name={'mode'}
                            value={'normal'}
                            type={'radio'}
                            onChange={(e) => this.modeChange('normal')}
                            id={'modeRadio'}
                            checked={this.state.mode === 'normal'}
                            disabled={this.state.fetching}
                        />
                        <Form.Check
                            inline
                            label={'Tak'}
                            name={'mode'}
                            value={'blurred'}
                            type={'radio'}
                            onChange={(e) => this.modeChange('blurred')}
                            id={'modeRadio2'}
                            checked={this.state.mode === 'blurred'}
                            disabled={this.state.fetching}
                        />

                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        Wybierz media
                    </Col>
                    <Col xs={12} className='text-center upperCase py-3'>
                        {this.renderMedia()}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center py-3'>
                        <span style={{ marginRight: '15px' }}>
                            Wyślij:
                        </span>
                        <Form.Check
                            inline
                            label={'Do fanów'}
                            name={'sendTest'}
                            value={'normal'}
                            type={'radio'}
                            onClick={(e) => this.setState({ sendTest: false })}
                            id={'sendTestRadio'}
                            checked={this.state.sendTest ? false : true}
                            disabled={this.state.fetching}
                        />
                        <Form.Check
                            inline
                            label={'Test do siebie'}
                            name={'sendTest'}
                            value={'blurred'}
                            type={'radio'}
                            onClick={(e) => this.setState({ sendTest: true })}
                            id={'sendTestRadio2'}
                            checked={this.state.sendTest ? true : false}
                            disabled={this.state.fetching}
                        />

                    </Col>
                    {this.state.data &&
                        <Col xs={12} className="text-center">
                            Odbiorcy: {this.state.data.receiversCount}
                        </Col>
                    }
                    <Col xs={12} className="text-center">
                        <Button variant={'primary'} onClick={() => this.fetch(true)}>WYŚLIJ</Button>
                    </Col>
                </Row>
                {this.state.feching &&
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                }
                {this.state.data &&
                    <Row noGutters>
                        <Col xs={12} className="text-center">
                            Podgląd:
                        </Col>
                        <Col xs={12}>
                            <Container>
                                <iframe srcDoc={this.state.data.preview} style={{ width: '100%', height: '80vh' }}>
                                </iframe>
                            </Container>


                        </Col>
                    </Row>
                }

            </Col>
        )
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                newPostMailingPreview,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPostMailingNots);

