
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getZarobkiDetails,  refund, getRefundReasons, getRefundReasonCategories, } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';

import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import DialogToast from "../components/dialogToast";
import LoaderAbsoluteOverlay from "../components/loaderAbsoluteOverlay";
import Form from 'react-bootstrap/Form';

class ZarobkiDetailsView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.setState({ id: id })
    getZarobkiDetails(id, this.dataFetched)

  }

  componentWillUnmount() {
  }

  state = {
    refundReason: '',
  }

  dataFetched = (data) => {
    console.log('dataFetched', data)
    if (!data.error) {
      this.setState({ data: data })
    }

  }
  handeDotpayRequestClick = (item) => {
    this.setState({ redirect: '/dotpayRequests/details/' + item.id + '/' })

  }
  renderDotpayRequest = () => {
    if(!this.state.data.dotpayRequest){
      return null
    }
    return (
      <tr className='cursor'>
        <td className="" onClick={() => { this.handeDotpayRequestClick(this.state.data.dotpayRequest) }}>
          {this.state.data.dotpayRequest.time}
        </td>
        <td className="" onClick={() => { this.handeDotpayRequestClick(this.state.data.dotpayRequest) }}>
          {this.state.data.dotpayRequest.provider}
        </td>
        <td className="" onClick={() => { this.handeDotpayRequestClick(this.state.data.dotpayRequest) }}>
          {this.state.data.dotpayRequest.plnPriceTotal} PLN
        </td>
        <td className="" onClick={() => { this.handeDotpayRequestClick(this.state.data.dotpayRequest) }}>
          {this.state.data.dotpayRequest.euroPriceTotal} EUR
        </td>
        <td className="upeprCase" onClick={() => { this.handeDotpayRequestClick(this.state.data.dotpayRequest) }}>
          {this.state.data.dotpayRequest.currency}
        </td>
      </tr>
    )
  }
  initRefund = () => {
    if (!this.state.data.dotpayRequest) {
      //alert ze brak dotpayrequest i ze trzeba usunac recznie znajdujac this.state.data.idTransakcji
      return
    }
    let idTransakcji = this.state.data.idTransakcji
    let provider
    let url;
    if (this.state.data.dotpayRequest.provider) {
      provider = this.state.data.dotpayRequest.provider.toLowerCase()
    }
    else {
      provider = 'unknown'
    }
    if (provider === 'dotpay') {
      //robimy przez API
      url = 'https://ssl.dotpay.pl/s2/login/payments/' + idTransakcji + '/view_r/'
    }
    else if (provider === 'hotpay') {
      url = 'https://hotpay.pl/przelewy_statystyki/'
    }
    else if (provider === 'securion') {
      //url='https://securionpay.com/charges/'+idTransakcji+'/refund'
      url = 'https://securionpay.com/charges/' + idTransakcji
      //albo zrobic bezposrednio przez API!
    }
    this.setState({ showRefundModal: true, provider: provider, refundUrl: url })
  }
  renderToastContent = (t, msg) => {
    return (
      <div style={{ paddingRight: '25px' }}>
        {msg}
        <div onClick={() => { toast.dismiss(t.id) }}
          style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
        >
          X
        </div>
      </div>
    )
  }
  makeRefund = (confirmed = false) => {
    toast.dismiss()
    if (this.state.refundReason.length === 0) {
      toast.error((t) => { return this.renderToastContent(t, 'Musisz wpisać powód refundacji.') })
      return
    }
    if (!confirmed) {
      DialogToast('Czy napewno chcesz dokonać refundacji?', () => this.makeRefund(true))
      return
    }
    this.setState({ refundFetching: true })
    toast.dismiss()
    console.log('makeRefund')
    const fd = new FormData()
    fd.append('provider', this.state.provider)

    fd.append('idTransakcji', this.state.data.idTransakcji)
    fd.append('refundReason', this.state.refundReason)

    this.props.refund(fd, this.refundFetched)
  }

  refundFetched = (data) => {
    console.log('refundFetched', data)
    toast.dismiss()
    this.setState({ refundFetching: false })
    if (data.error) {
      toast.error((t) => { return this.renderToastContent(t, data.message) })
      return
    }
    toast.success((t) => { return this.renderToastContent(t, 'Refundacja zakonczona sukcesem!') })
    this.setState({ data: data })
  }

  RefundModalOnHide = () => {
    this.setState({ showRefundModal: false })
  }

  getRefundReasonCategories = () => {
    this.setState({ refundFetching: true })
    this.props.getRefundReasonCategories(this.refundCategoriesFetched)
  }
  refundCategoriesFetched = (data) => {
    console.log('refundCategoriesFetched', data)
    this.setState({ refundFetching: false })
    if (!data.error) {
      this.setState({ refundCategories: data })
    }

  }
  refundCategoryOnChange = (e) => {
    console.log('refundCategoryOnChange', e.target.value)
    this.setState({ refundFetching: true })
    this.props.getRefundReasons(e.target.value, this.refundReasonsFetched)

  }
  refundReasonsFetched = (data) => {
    this.setState({ refundFetching: false })
    if (!data.error) {
      this.setState({ refundReasons: data })
    }
  }
  refundReasonOnChange = (e) => {
    this.setState({ refundReason: e.target.value })
  }


  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/login/'} />
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />
    }

    return (
      <Col xs={12} className='p-0'>
        <Toaster />
        <Row noGutters>
          <Col xs={12} className='text-center upperCase py-3'>
            <h3>SZCZEGÓŁY TRANSAKCJI (ZAROBKI)</h3>
          </Col>
        </Row>
        {!this.state.data &&
          <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
          </Container>
        }
        {this.state.data &&
          <Row noGutters>
            <Col xs={12} className='px-3'>
              <Row noGutters>
                <Col xs={12} md={6} className='py-3'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h5>Produkt:</h5>
                    </Col>
                    <Col xs={12}>
                      {this.state.data.rodzaj === 1 &&
                        <Row noGutters>
                          <Col xs={12}>
                            Abonament do profilu (dodac linki)
                          </Col>
                        </Row>
                      }
                      {this.state.data.rodzaj === 2 &&
                        <span>Napiwek</span>
                      }
                      {this.state.data.rodzaj === 3 &&
                        <span>Program partnerski</span>
                      }
                      {this.state.data.rodzaj === 4 &&
                        <Row noGutters>
                          <Col xs={12}>
                            Galeria Pay Per View
                          </Col>

                          {this.state.data.ppvSub &&
                            <Row noGutters>
                              <Col xs={12}>
                                Odnawialny: {this.state.data.ppvSub.isRecuring ? 'Tak' : 'Nie'}
                              </Col>
                              {this.state.data.ppvSub.isRecuring &&
                                <Col xs={12}>
                                  ID subskrypcji odnawialnej: {this.state.data.ppvSub.secSubscriptionId}
                                </Col>
                              }

                              <Col xs={12}>
                                Status: {this.state.data.subDetails.expired ? 'Wygasł' : 'Aktywny'}
                              </Col>
                              <Col xs={12}>
                                {this.state.data.ppvSub.isRecuring &&
                                  'Data odnowienia: '
                                }
                                {!this.state.data.ppvSub.isRecuring &&
                                  'Data wygaśnięcia: '
                                }
                                {this.state.data.subDetails.date}
                              </Col>
                              <Col xs={12}>
                                (dodac szczegoly i linki)
                              </Col>

                            </Row>
                          }
                        </Row>
                      }
                      {this.state.data.rodzaj === 5 &&
                        <Row noGutters>
                          <Col xs={12}>
                            Pakiet Snapchat
                          </Col>
                          {!this.state.data.snapPacket &&
                            <Col xs={12}>
                              Brak danych pakietu snapchat
                            </Col>
                          }
                          {this.state.data.snapPacket &&
                            <Col xs={12}>
                              Wykupiony: {this.state.data.snapPacket.created}
                            </Col>
                          }
                          {this.state.data.snapPacket &&
                            <Col xs={12}>
                              Wygasa: {this.state.data.snapPacket.expirationTime}
                            </Col>
                          }
                          {this.state.data.snapPacket &&
                            <Col xs={12}>
                              Potwierdzony: {this.state.data.snapPacket.confirmed ? 'Tak' : 'Nie'}
                            </Col>
                          }
                          {this.state.data.snapPacket &&
                            <Col xs={12}>
                              Data potwierdzenia: {this.state.data.snapPacket.confirmationTime ? this.state.data.confirmationTime : 'Brak'}
                            </Col>
                          }
                        </Row>
                      }
                    </Col>
                    <Col xs={12} className='py2'>
                      ID transakcji: <span className="strong textBlueGrey">{this.state.data.idTransakcji}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={6} className='py-3'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h5>Kupujący:</h5>
                    </Col>
                    <Col xs={12}>
                      {this.state.data.wplacajacy.displayName} - Szczegóły konta link
                    </Col>
                    <Col xs={12}>
                      Cena: {this.state.data.wplacono&&this.state.data.wplacono.price} <span className="upperCase">{this.state.data.wplacono&&this.state.data.wplacono.currency}</span>
                    </Col>
                    <Col xs={12}>
                      Kwota transakcji: {this.state.data.wplacono&&this.state.data.wplacono.priceTotal} <span className="upperCase">{this.state.data.wplacono&&this.state.data.wplacono.currency}</span>
                    </Col>
                    <Col xs={12}>
                      Data: {this.state.data.time}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className='py-3'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h5>Sprzedający:</h5>
                    </Col>
                    <Col xs={12}>
                      {this.state.data.blogger.displayName} - Szczegóły konta link
                    </Col>
                    <Col xs={12}>
                      Kwota do wypłaty: {this.state.data.kwotaDoWyplaty} <span className="upperCase">{this.state.data.waluta}</span>
                    </Col>
                    <Col xs={12}>
                      Wypłacona:<span> </span>
                      {this.state.data.payoutStatus === 0 &&
                        <span>Nie</span>
                      }
                      {this.state.data.payoutStatus === 1 &&
                        <span>Oczekuje</span>
                      }
                      {this.state.data.payoutStatus === 1 &&
                        <span>Tak</span>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className='py-3'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h5>Serwis:</h5>
                    </Col>

                    <Col xs={12} className=''>
                      Procent Serwisu: <span className='upperCase'>{this.state.data.procentSerwisu * 100}%</span>
                    </Col>
                    <Col xs={12} className=''>
                      Prowizja Serwisu: <span className='upperCase'>{this.state.data.prowizjaSerwisu}{this.state.data.waluta}</span>
                    </Col>
                    <Col xs={12}>
                      Markup Serwisu: {this.state.data.markup}{this.state.data.waluta}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className='py-3'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h5>Program Partnerski:</h5>
                    </Col>
                    <Col xs={12}>
                      jezeli jest, podac parntera
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className='py-3 text-center'>
              <h5>Refundacja</h5>
              {!this.state.data.refundId && !this.state.data.isRefund &&
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => { this.initRefund() }}
                  disabled={this.state.searchFetching}
                >
                  REFUNDACJA NA RZECZ KUPUJĄCEGO
                </Button>
              }
              {this.state.data.refundId &&
                <Col xs={12} className='text-center py-2' >
                  <p className="text-danger">Transakcja została zrefundowana</p>
                  <p>Powód:<br></br>{this.state.data.refundReason}</p>
                  <a href={'/zarobki/details/' + this.state.data.refundId + '/'}>Zobacz szczegóły refundacji</a>
                </Col>
              }
              {this.state.data.isRefund &&
                <Col xs={12} className='text-center py-2' >
                  <p className="text-danger">Transakcja jest refundacją</p>
                  <p>Powód:<br></br>{this.state.data.refundReason}</p>
                </Col>
              }
            </Col>
            <Col xs={12} className='py-3 text-center'>
              <h5>Dotpay requests:</h5>
            </Col>
            <Col xs={12}>
              <Table className="" bordered hover>
                <thead>
                  <tr>
                    <th scope="col" >Data</th>
                    <th scope="col" >Provider</th>
                    <th scope="col">Total PLN</th>
                    <th scope="col">Total EUR</th>
                    <th scope="col">Waluta transakcji</th>
                  </tr>
                </thead>
                <tbody >
                  {this.renderDotpayRequest()}
                </tbody>
              </Table >
            </Col>

            <Col xs={12} md={6} className='py-3'>
              <Row noGutters>
                <Col xs={12}>
                  <h5>Powiązane obiekty:</h5>
                </Col>
                <Col xs={12}>
                  abonament,
                  ppvSub,
                  snapPacket,
                  dotpayRequests,
                  wypłaty,
                </Col>
              </Row>
            </Col>
          </Row>
        }
        <Modal

          centered
          show={this.state.showRefundModal}
          onHide={() => this.RefundModalOnHide()}
          onShow={() => this.getRefundReasonCategories()}
          dialogClassName="modal-90h  flex-column"
        >
          <Modal.Body className='p-0  '>
            <Row noGutters className=''>
              <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
                {this.state.data &&
                  <Row noGutters>
                    {this.state.refundFetching &&
                      <LoaderAbsoluteOverlay size={70} />
                    }
                    <Col xs={12} className='text-center' >
                      <span style={{ fontSize: '23px' }}>REFUNDACJA:</span>
                    </Col>

                    <Col xs={12} className='text-center py-2' >
                      Refundacja kwoty  <span className="strong textBlueGrey">
                        {this.state.data.wplacono&&this.state.data.wplacono.priceTotal} {this.state.data.wplacono&&this.state.data.wplacono.currency}
                      </span> na rzecz kupującego.
                    </Col>

                    <Col xs={12} className='text-center py-2 px-2' >
                      <Form.Control as="select"
                        ref={this.selectRef}
                        onChange={(e) => this.refundCategoryOnChange(e)}
                        disabled={this.state.fetching}
                        size={'sm'}
                      >
                        <option value="0">Kategoria...</option>
                        {this.state.refundCategories && this.state.refundCategories.map((item, index) => {
                          return (<option key={'refc' + index} value={item.id}>{item.title}</option>)
                        })}
                      </Form.Control>
                    </Col>
                    <Col xs={12} className='text-center py-2 px-2' >
                      <Form.Control as="select"
                        ref={this.selectRef}
                        onChange={(e) => this.refundReasonOnChange(e)}
                        disabled={this.state.fetching}
                        size={'sm'}
                      >
                        <option>Treść...</option>
                        {this.state.refundReasons && this.state.refundReasons.map((item, index) => {
                          return (<option key={'refReas' + index} value={item.contentPl}>{item.title}</option>)
                        })}
                      </Form.Control>
                    </Col>

                    <Col xs={12} className='text-center py-2 px-2' >
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          placeholder="Podaj powód refundacji"
                          as="textarea"
                          rows={3}
                          value={this.state.refundReason}
                          onChange={(e) => { this.setState({ refundReason: e.target.value }) }}
                        />
                      </Form.Group>
                    </Col>
                    {this.state.provider && this.state.provider.toLowerCase() === 'hotpay' && !this.state.data.refundId &&
                      <Col xs={12}>
                        <Row noGutters>
                          <Col xs={12} className='text-center py-2' >
                            <p>Automatyczna refundacja nie jest możliwa.</p>
                            <p>Wykonaj ją ręcznie wyszukując transakcje korzystając z poniższego linku:</p>
                            <p><a href={this.state.refundUrl} target='_blank'>{this.state.refundUrl} </a></p>
                            <p>ID transakcji: <br></br>
                              <span className="strong textBlueGrey">{this.state.data.idTransakcji}</span>
                            </p>
                            <p>Następnie oznacz ją jako zrefundowaną</p>
                          </Col>
                          <Col xs={12} className='text-center py-2' >
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => { this.makeRefund() }}
                              disabled={this.state.refundFetching}
                            >
                              OZNACZ JAKO ZREFUNDOWANĄ
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    }

                    {this.state.provider && this.state.provider !== 'hotpay' && !this.state.data.refundId &&
                      <Col xs={12} className='text-center py-2' >
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => { this.makeRefund() }}
                          disabled={this.state.refundFetching}
                        >
                          DOKONAJ REFUNDACJI
                        </Button>
                        {this.state.provider}
                      </Col>
                    }
                    {(this.state.provider === 'dotpay' || this.state.provider === 'securion') &&
                     <Col xs={12} className='text-center py-2' >
                    <p className="strong">
                        Refundacja dokona się automatycznie.<br></br>
                        wypelnij i zatwierdz formularz.

                    </p>
                    
                  </Col>
                }
                {(this.state.provider !== 'dotpay' && this.state.provider !== 'securion') &&
                  <Col xs={12} className='text-center py-2' >
                    <p className="strong">Automatyczna refundacja nie jest możliwa,<br></br>
                      Musisz wyszukać transakcję ręcznie
                    </p>
                    <p>ID transakcji: <br></br>
                      <span className="strong textBlueGrey">{this.state.data.idTransakcji}</span>
                    </p>
                  </Col>
                }
                {this.state.data.refundId &&
                  <Col xs={12} className='text-center py-2' >
                    <p className="text-danger">Transakcja została zrefundowana</p>
                    <a href={'/zarobki/details/' + this.state.data.refundId + '/'}>Zobacz sczegóły refundacji</a>
                  </Col>
                }
              
            </Row>
                }
          </Col>
        </Row>
      </Modal.Body>
        </Modal >
      </Col >
    )

  }

}



function mapStateToProps(state) {
  return {
    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getZarobkiDetails,
        refund,
        getRefundReasonCategories,
        getRefundReasons,
      }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZarobkiDetailsView);

