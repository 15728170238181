
import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
function TransactionListModal(props) {
  console.log(props.data)
  const [day, setDay] = useState(0);
  const prevPage = () => {
    setDay(day + 1)
    console.log(day)
    props.fetch(day)
  }
  const nextPage = () => {

    if (day === 0) {
      return
    }
    setDay(day - 1)
    props.fetch(day)
  }

  return (
    <Modal
      size="lg"
      centered
      show={props.show}
      onHide={props.onHide}
      onShow={() => props.fetch(day)}
      dialogClassName="modal-90h  flex-column"
    >
      <Modal.Body className='p-0 modal-90h '>
        <Row noGutters className=''>
          <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
            <Row noGutters>
              <Col xs={'auto'} className='pl-2 cursor' onClick={() => prevPage()}>
                <BsFillArrowLeftCircleFill style={{ fontSize: '28px' }} />
              </Col>
              <Col className='text-center' >
                {props.fetching &&
                  <Spinner animation="border" role="status" variant="danger" >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
                <span style={{ fontSize: '23px' }}>{props.data && props.data.date}</span>
              </Col>
              <Col xs={'auto'} className='pr-2 cursor' onClick={() => nextPage()}>
                <BsFillArrowRightCircleFill style={{ fontSize: '28px' }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row noGutters className="overflow-auto  border" style={{ height: '76vh', overflow: 'auto' }}>
          {props.data && props.data.listData.length === 0 &&
            <Col className="text-center" >
              Brak transakcji
            </Col>
          }
          {props.data && props.data.listData.length > 0 &&
          
            <Col xs={12}>
              <Row noGutters>
                <Col xs={12} className="text-center py-2 border-bottom" style={{ fontSize: '22px' }}>
                  <strong>SUMA: {props.data.sumaPln} PLN</strong>
                </Col>
              </Row>

              <Row noGutters>
                <Col xs={6} md={3} className="strong py-2 pl-2">
                  From
                </Col>
                <Col xs={6} md={3} className="strong py-2 pl-2">
                  To
                </Col>
                <Col xs={6} md={3} className="strong py-2 pl-2 d-none d-md-block">
                  Amount
                </Col>
                <Col xs={6} md={3} className="strong py-2 pl-2 d-none d-md-block">
                  Type
                </Col>
              </Row>
              {props.data && props.data.listData.map((item, index) => {

                let type = item.type
                if (type && type.toLowerCase() === 'ppv') {
                  type = <Link to={{ pathname: 'https://www.watchmemore.com/' + item.bloggerUrlName + '/ppv/' + item.extraData + '/' }} target={'_blank'}>
                    {item.type}
                  </Link>
                }
                return (
                  <Row noGutters key={'tiItem' + index}>
                    <Col xs={6} md={3} className="strong pt-2 pb-md-2 pl-2 border-top">
                      <strong>
                        <a href={"user/"+item.fan.id+"/"} target="_blank">
                        {item.fan.displayName}
                        </a>
                      </strong>
                    </Col>
                    <Col xs={6} md={3} className="strong pt-2 pb-md-2 border-top">
                      <strong>
                        <a href={"user/"+item.blogger.id+"/"} target="_blank">
                          {item.blogger.displayName}
                        </a>
                      </strong>
                    </Col>
                    <Col xs={6} md={3} className="strong upperCase pb-2  pt-md-2 pl-2  border-md-top">
                      <strong>
                        <a href={"/zarobki/details/"+item.id+"/"} target="_blank">
                        {item.walutaTransakcji.toLowerCase()==='pln'&&
                          item.kwotaPln+' '+item.walutaTransakcji
                        }
                        {item.walutaTransakcji.toLowerCase()==='eur'&&
                          item.kwotaEur+' '+item.walutaTransakcji
                        }
                        </a>
                      </strong>
                    </Col>
                    <Col xs={6} md={3} className="strong upperCase  pb-2  py-md-2 pl-2 pt-2 border-md-top">
                      <strong>
                        {type}
                      </strong>
                    </Col>
                  </Row>
                )
              })}

            </Col>
          }


        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'outline-danger'} onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default TransactionListModal