import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getZarobkiDetails=(pk, callBack) =>{
    let path=ApiUrl+"zarobki/"+pk+"/"

    console.log('getZarobkiDetails path', path)
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getZarobkiDetails response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getZarobkiDetails error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
  }
  export const getDotpayRequestDetails=(pk, callBack) =>{
    let path=ApiUrl+"dotpayRequests/"+pk+"/"
    console.log('getDotpayRequestDetails path', path)
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getDotpayRequestDetails response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getDotpayRequestDetails error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
  }
  
  export const SearchZarobkiByTransId=(idTransakcji, callBack) =>{
    let path=ApiUrl+"search/transaction/"+idTransakcji+"/"
    console.log('SearchZarobkiByTransId path', path)
      axiosClient.get(path)
      .then( (response)=> {
        console.log('SearchZarobkiByTransId response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('SearchZarobkiByTransId error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
  }
  