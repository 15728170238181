import { createReducer } from '@reduxjs/toolkit'
const initialState={
    dashboardData:undefined,
}
const dashboard = createReducer(initialState, (builder) => {
    builder
      .addCase('SET_DASHBOARD_DATA', (state, action) => {
      //  console.log('reducer SET_AUTH_TOKEN', action.data)
        state.dashboardData=action.data
      })
  })

export default dashboard