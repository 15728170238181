import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const newPostMailingPreview=(postId, fd, callBack) =>{
    let path=ApiUrl+"newpostmailing/"+postId+'/'
    //console.log('newPostMailingPreview path', path)
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('newpostmailing response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('newpostmailing error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }
