import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getSprzedane,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Table from 'react-bootstrap/Table';

class SprzedaneBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        this.nextPage()
    }

    state = {
        fetching: false,
        page: 1,
        hasMorePages: true,

    }
    nextPage = () => {
        if (!this.state.fetching && this.state.hasMorePages) {
            this.setState({ fetching: true })
            this.props.getSprzedane(this.props.user.id, this.state.page, this.dataFetched)
        }
    }
    dataFetched = (data) => {
        console.log('KupioneBox dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            if (this.state.data) {
                let oldData = JSON.parse(JSON.stringify(this.state.data))
                data= [...oldData, ...data]
                console.log('new dataFetched data', data)
            }
            else {
                data = data
            }
            this.setState({ data: data, page: this.state.page + 1 })
        }
        else {
            this.setState({ hasMorePages: false })
        }
    }
    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    showDetails = (item) => {
        console.log('showDetails', item)
        this.setState({ redirect: '/zarobki/details/' + item.id + '/' })
        ///zarobki/details/:id/
    }
    renderList = () => {
        return (
            this.state.data.map((item, index) => {
                //1- abonament, 2- tip, 3-referral program, 4 - ppv, 5 - snapy
                let rodzaj = ''
                if (item.rodzaj === 1) {
                    rodzaj = 'Sub'
                }
                else if (item.rodzaj === 2) {
                    rodzaj = 'Tip'
                }
                else if (item.rodzaj === 3) {
                    rodzaj = 'Pp'
                }
                else if (item.rodzaj === 4) {

                    if (item.subDetails.containerName) {
                        rodzaj = (
                            <Link to={{ pathname: 'https://www.watchmemore.com/' + item.blogger.urlName + '/ppv/' + item.subDetails.containerName + '/' }} target={'_blank'} >
                                PPV
                            </Link >
                        )
                    }
                    else {
                        rodzaj = 'PPV'
                    }
                }
                else if (item.rodzaj === 5) {
                    rodzaj = 'Sn'
                }
                else {
                    rodzaj = 'Inny'
                }
                let kwota;
                if (item.walutaTransakcji.toLowerCase() === 'pln') {
                    kwota = item.kwotaDoWyplatyPln
                }
                else if (item.walutaTransakcji.toLowerCase() === 'eur') {
                    kwota = item.kwotaDoWyplatyEur
                }
                else {
                    kwota = '????'
                }
                return (
                    <tr key={'kupionetem' + index} className='cursor'>
                        <td className="cursor" onClick={() => this.showDetails(item)}>
                            {item.time}
                        </td>
                        <th scope="row" className="capitalize">
                            <strong>
                                <Link to={{ pathname: '/user/' + item.wplacajacy.id + '/' }} target={'_blank'}>
                                    {item.wplacajacy.displayName}
                                </Link>
                            </strong>
                        </th>
                        <td className="">
                            {rodzaj} 

                        </td>
                        {item.isRefund &&
                            <td className="cursor text-danger" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        {item.refund &&
                            <td style={{ textDecoration: "line-through" }} className="cursor text-danger" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        {!item.refund && !item.isRefund &&
                            <td className="cursor" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        <td className={item.subDetails.isSub && item.subDetails.expired ? "text-center, text-danger, cursor" : "text-center, cursor"}
                            onClick={() => this.showDetails(item)}
                        >
                            {item.subDetails.isSub && !item.isRefund &&
                                item.subDetails.date
                            }
                            {!item.subDetails.isSub && !item.isRefund &&
                                '-'
                            }
                            {item.isRefund &&
                                <span className="text-danger">Zwrot</span>
                            }
                        </td>
                    </tr>
                )
            })
        )
    }

    chatDetailsOnScroll = (e) => {
        // console.log(e)
        //find when scroll is on top
        let scrollPosition = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
        // console.log('scrollPosition', scrollPosition)
        if (scrollPosition < 100) {
            this.nextPage()
        }

    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3 text-center'>
                    <Toaster />
                    <h5>SPRZEDANE:</h5>
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3 '>
                            <Row noGutters>
                                {this.state.data && this.state.data.length === 0 &&
                                    <Col xs={12} className='text-center pb-2'>
                                        Brak transakcji
                                    </Col>
                                }
                                {this.state.data && this.state.data.length > 0 &&
                                    <Col xs={12} className='text-center ' style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                        <Row noGutters>
                                            <Col xs={12} className='text-left py-2'>
                                                <small>*Sub-abonament, PPV-pay per view, Sn-SNAP, Tip-napiwek, Pp- program partnerski</small>
                                            </Col>
                                        </Row>
                                        <div className="kupioneListCont" onScroll={this.chatDetailsOnScroll}>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" >Data</th>
                                                        <th scope="col" >User</th>
                                                        <th scope="col">Typ</th>
                                                        <th scope="col">Kwota</th>
                                                        <th scope="col">Wygasa</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {this.renderList()}
                                                </tbody>
                                            </Table>
                                            {!this.state.hasMorePages &&
                                                <Row noGutters>
                                                    <Col xs={12} className='text-center py-2'>
                                                        Koniec listy.
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </Col>
                                }
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getSprzedane,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SprzedaneBox);