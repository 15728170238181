import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userLoginFetch} from "../../actions/index";
import { Redirect } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {AiOutlineMail} from 'react-icons/ai'
import {RiLockPasswordLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import {BsFillEyeSlashFill, BsFillEyeFill} from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
//import { parseParams } from '../../actions/helpers'

class Login extends Component {

  componentDidMount=()=>{
   // const params = parseParams(this.props?.location?.search); 
 //   let redirect='/'
  //  if(params.hasOwnProperty('next')){
 //     redirect=params.next
 //   }
 //   this.setState({redirect:redirect})
  }


  state = {
    email: "",
    password: "",
    errors:false,
    fetching:false,
    showPassword:false,
    redirect:null,
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    this.setState({errors:false, fetching:true})
    event.preventDefault()
    this.props.userLoginFetch(this.state, this.formErrors)
  }

  formErrors=(data)=>{
    this.setState({errors:data.data, fetching:false})
  }
  render() {
   // console.log(this.props.loggedIn)
    if (this.props.loggedIn) {
      return <Redirect to={'/'} />
    }
    return(
      <Container fluid style={{backgroundColor:'#fff', minHeight:'100vh'}} className="px-0">
        <Container className="px-0 pb-4">
          <Row noGutters>
            <Col xs={12} md={6} className='mx-auto'>
              <Row noGutters>
                <Col xs={12} className='p-2 p-md-5 pt-5 text-center'>
                    <Row noGutters>
                        <Col xs={12} className='border p-2 p-md-3 text-center pb-5 fadeIn'>
                          <Row noGutters>
                            <Col xs={12} className=' p-3 text-center'>
                              <div className='header-1'>
                                LOGIN
                              </div>
                              <hr></hr>
                            </Col>
                            <Col xs={12} className=''>
                              <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                Login/email
                              </Form.Label>
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                  <AiOutlineMail />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                  
                                  name='email'
                                  placeholder="Login/email" 
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                  disabled={this.state.fetching}
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={12} className='pt-3'>
                              <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                PASSWORD
                              </Form.Label>
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    <RiLockPasswordLine/>
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                  type={this.state.showPassword?"text":"password"}
                                  placeholder={'password'}
                                  name='password'
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  disabled={this.state.fetching}
                                />
                                    <InputGroup.Append onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}>
                                    <InputGroup.Text>
                                    {!this.state.showPassword&&
                                      <BsFillEyeSlashFill />
                                    }
                                    {this.state.showPassword&&
                                       <BsFillEyeFill />
                                    }
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                              </InputGroup>
                            </Col>
                            <Col xs={12} className="text-center text-danger" style={{height:'15px'}}>
                           
                              {this.state.errors&&this.state.errors.non_field_errors&&
                              this.state.errors.non_field_errors.map((item, index)=>{
                                return <div key={'erra'+index}>{item}</div> 
                              })
                                
                              }
                            </Col>
                            
                            <Col xs={12} className='pt-3 text-center'>
                                <Button variant={'red'} className='w-100 upperCase'
                                  onClick={this.handleSubmit}
                                  disabled={this.state.fetching}
                                >
                                  
                                  {this.state.fetching?
                                          <Oval type="Oval" color="rgba(255, 255, 255, 1)" height={18} width={80} />
                                          :'LOGIN'
                                          }
                                </Button>
                            </Col>
                           
                          </Row>
                        </Col>  
                    </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </Container>
    )
    

  }
}


function mapStateToProps(state) {
    return {
     loggedIn: state.auth.loggedIn
    };
  }
export default connect(
    mapStateToProps,
    {userLoginFetch, }
  )(Login);
