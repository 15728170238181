import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    updateProfile,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";

class OgraniczeniaBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
    }
    state = {
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }

    handleRadio = (e, confirmed) => {
        console.log(e.target.value)
        toast.dismiss()
        if (!confirmed) {
            if (e.target.name === 'permanentBan' && e.target.value === 'true') {
                DialogToast('Czy chcesz nałożyć całkowity ban profilu? Wszystkie zakupione oraz sprzedane przez tego użytkownika odnawialne subskrypcje zostaną bezpowrotnie anulowane.', () => this.handleRadio(e, true))
                return
            }
            let txt = ''
            if (e.target.value === 'true') {
                txt = 'nałożyć'
            }
            if (e.target.value === 'false') {
                txt = 'zdjąć'
            }
            DialogToast('Czy napewno ' + txt + ' ograniczenie?', () => this.handleRadio(e, true))
            return
        }
        this.setState({ fetching: true })
        let data = { [e.target.name]: e.target.value }
        data = JSON.stringify(data)
        console.log(data)
        const fd = new FormData()
        fd.append('data', data)
        this.props.updateProfile(this.props.user.id, fd, this.formSaved)


    }


    formSaved = (data) => {
        console.log('formSaved', data)
        this.setState({ fetching: false })
        if (!data.error) {
            toast.success((t) => { return this.renderToastContent(t, 'Zapisane') })
        }
        else {
            let msg = ''
            if (data.data.message) {
                msg = data.data.message
            }
            else {
                msg = data.data
            }
            toast.error((t) => { return this.renderToastContent(t, msg) })
        }
    }

    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3 text-center'>
                    <h5>OGRANICZENIA:</h5>
                    <Toaster />
                    <Row noGutters>

                        <Col xs={12} className='py-3 textBlueGrey '>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj sprzedaż:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banSale'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banSale'}
                                        checked={this.props.profile.banSale === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banSale'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banSale2'}
                                        checked={this.props.profile.banSale === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>

                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj sprzedaż Snapów:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banSnapSell'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banSnapSell'}
                                        checked={this.props.profile.banSnapSell === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banSnapSell'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banSnapSell2'}
                                        checked={this.props.profile.banSnapSell === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj wypłaty:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banPayouts'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banPayouts'}
                                        checked={this.props.profile.banPayouts === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banPayouts'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banPayouts2'}
                                        checked={this.props.profile.banPayouts === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj dodawanie postów:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banPosts'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banPosts'}
                                        checked={this.props.profile.banPosts === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banPosts'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banPosts2'}
                                        checked={this.props.profile.banPosts === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj dodawanie komentarzy:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banComments'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banComments'}
                                        checked={this.props.profile.banComments === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banComments'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banComments2'}
                                        checked={this.props.profile.banComments === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Zablokuj czat:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'banChat'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banChat'}
                                        checked={this.props.profile.banChat === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'banChat'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'banChat2'}
                                        checked={this.props.profile.banChat === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Shadow ban profilu:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'shadowBan'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'shadowBan'}
                                        checked={this.props.profile.shadowBan === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'shadowBan'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'shadowBan2'}
                                        checked={this.props.profile.shadowBan === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={6} className='text-right pr-3'>
                                    Całkowity ban usera:
                                </Col>
                                <Col xs={6} className='text-left pl-2'>
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'permanentBan'}
                                        value={false}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'permanentBan'}
                                        checked={this.props.profile.permanentBan === false && true}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'permanentBan'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => { this.handleRadio(e) }}
                                        id={'permanentBan2'}
                                        checked={this.props.profile.permanentBan === true && true}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                updateProfile,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OgraniczeniaBox);