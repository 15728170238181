
import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import { isMobile } from 'react-device-detect';

const licenseKey = 'EBAD8C94-B5724B8E-8EB24C0A-8A800C2E'
function VerificationPicture(props) {
    let src = props.item.image
    if (props.item.sas) {
        src = src + props.item.sas
    }
    return (
        <div className="">
            <Row noGutters className={props.item.declined?"border-danger trans30":"border"}>
                {props.item.title &&
                    <Col xs={12} className=' text-center'>
                        <strong>{props.item.title}</strong>
                    </Col>
                }
                <Col xs={12} className=''>
                    <LightGallery
                        closeOnTap={true}
                        isMobile={isMobile}
                        licenseKey={licenseKey}
                    //   onInit={onInit}
                    // speed={500}
                        plugins={[lgZoom]}
                    >
                        <a href={src}>
                            <Image src={src} height={150} />
                        </a>
                    </LightGallery>
                    <Row noGutters>
                        <Col xs={12} className=' pb-1 pt-1'>
                            <small>{props.item.date}</small>
                        </Col>
                        
                    </Row>

                </Col>

            </Row>


        </div>
    )
}
export default VerificationPicture