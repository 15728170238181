
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { podsumowanieMiesiaca, } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';

import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import DialogToast from "../components/dialogToast";
import LoaderAbsoluteOverlay from "../components/loaderAbsoluteOverlay";
import Form from 'react-bootstrap/Form';

class PodsumowanieMiesiacaView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const d = new Date();
    let lastMonth = d.getMonth();
    lastMonth = lastMonth + 1
    let year = d.getFullYear();
    if (lastMonth == 1) {
      lastMonth = 12
      year = year - 1
    }
    else {
      lastMonth = lastMonth - 1
    }
    this.setState({ month: lastMonth, year: year })

  }

  componentWillUnmount() {
  }

  state = {
    month: '',
    year: '',
  }
  fetchData=()=>{
    this.setState({ fetching: true })
    const fd=new FormData()
    fd.append('month',this.state.month)
    fd.append('year',this.state.year)
    this.props.podsumowanieMiesiaca(fd, this.dataFetched)
  }
  dataFetched = (data) => {
    console.log('dataFetched', data)
    this.setState({ fetching: false })
    if (!data.error) {
      this.setState({ data: data })
    }

  }

  renderYears = () => {
    let years = []
    let d = new Date();
    let year = d.getFullYear();
    for (let i = year; i > 2018; i--) {
      if (i == this.state.year) {
        years.push(<option selected value={i}>{i}</option>)
      }
      else {
        years.push(<option value={i}>{i}</option>)
      }
    }
    //
    return years
  }

  renderMonths = () => {
    let months = []
    let monthsVerbal = []
    monthsVerbal[1] = 'Styczeń'
    monthsVerbal[2] = 'Luty'
    monthsVerbal[3] = 'Marzec'
    monthsVerbal[4] = 'Kwiecień'
    monthsVerbal[5] = 'Maj'
    monthsVerbal[6] = 'Czerwiec'
    monthsVerbal[7] = 'Lipiec'
    monthsVerbal[8] = 'Sierpień'
    monthsVerbal[9] = 'Wrzesień'
    monthsVerbal[10] = 'Październik'
    monthsVerbal[11] = 'Listopad'
    monthsVerbal[12] = 'Grudzień'
    for (let i = 1; i < 13; i++) {
      if (i == this.state.month) {
        months.push(<option selected value={i}>{monthsVerbal[i]}</option>)
      }
      else {
        months.push(<option value={i}>{monthsVerbal[i]}</option>)
      }
    }
    return months
  }

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/login/'} />
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />
    }

    return (
      <Col xs={12} className='p-0'>
        <Toaster />
        <Row noGutters>
          <Col xs={12} className='text-center upperCase py-3'>
            <h3>PODSUMOWANIE MIESIĄCA</h3>
            {this.state.month} {this.state.year}
          </Col>
          <Col xs={6}>
            <Form.Control as="select"
              custom
              onChange={(e) => { this.setState({ month: e.target.value }) }}
            >
              {this.renderMonths()}
            </Form.Control>
          </Col>
          <Col xs={6}>
            <Form.Control as="select"
              custom
              onChange={(e) => { this.setState({ year: e.target.value }) }}
            >
              {this.renderYears()}
            </Form.Control>
          </Col>
          <Col xs={12} className='text-center py-3'>
            <Button
              variant="primary"
              onClick={() => {
                this.fetchData()
              }}
            >
              POKAŻ
            </Button>
          </Col>
        </Row>
        {this.state.fetching &&
          <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
          </Container>
        }
        {this.state.data &&
          <Row noGutters>
            <Col xs={12} className='px-3'>
              <Row noGutters>
                <Col xs={12}  className='px-3 py-3'>
                  Wszystkie rozliczone transakcje: {this.state.data.wszystkieRozlicone}
                </Col>
                <Col xs={12} className='px-3 py-3'>
                  Przekazane twórcom: {this.state.data.przekazaneTworcom}
                </Col>
                <Col xs={12} className='px-3 py-3'>
                  Prowizja serwisu:  {this.state.data.prowizjaSerwisu}
                </Col>
                <Col xs={12} className='px-3 py-3'>
                 Vat: {this.state.data.vat}
                </Col>
                <Col xs={12} className='px-3 py-3'>
                 Faktury od:  {this.state.data.firstInvoice}
                </Col>
                 <Col xs={12} className='px-3 py-3'>
                  Faktury do: {this.state.data.lastInvoice}
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </Col >
    )

  }

}



function mapStateToProps(state) {
  return {
    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        podsumowanieMiesiaca,
      }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodsumowanieMiesiacaView);

