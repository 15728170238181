
import React ,{ useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

function ImageAnalizeModal(props) {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState(undefined);

    const onShow=()=>{

    }

    const onHide=()=>{
        
    }
    return (
        <Modal show={props.show} 
                fullscreen={true} 
                onShow={()=>onShow} 
                onHide={() => onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Modal
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal>
    )
  }
  export default ImageAnalizeModal