
import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function DashboardBox(props) {


 
    return (
        <Col xs={12} md={props.forceMd12?12:6} className='p-2'>
            <Row noGutters>
                <Col xs={12} className='border p-2'>
                    <Row noGutters>
                        <Col xs={12} className='border py-3 text-center'>
                            <Row noGutters style={{minHeight:'117px'}}>
                                <Col xs={12} className='text-center textBlueGrey cardSmallText'>
                                    {props.title}
                                </Col>
                                <Col xs={12} className='text-center textBlueGrey cardBigText'>
                                    {props.value}
                                </Col>
                                <Col xs={12} className='text-center textGrey cardSmallText'>
                                    {props.title2}
                                </Col>
                                {props.buttonAction&&
                                    <Col xs={12} className='text-center  cardSmallText link-primary'>
                                        <span className="cursor fakeLink" onClick={()=>{props.buttonAction()}}>
                                            {props.buttonText} 
                                        </span>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
  }
  export default DashboardBox