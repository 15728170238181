
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getUserSearchList } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { genders } from '../constants/common'
class UsersSearch extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
     this.fetch()

    }

    componentWillUnmount() {
    }

    state = {
        genders:[],
        isVerified:'all'
    }

    fetch=()=>{
        let payload={}
        if(this.state.genders.length>0){
            payload.genders=this.state.genders
        }
        if(this.state.isVerified!=='all'){
            payload.isVerified=this.state.isVerified
        }
        if(this.state.hasInstagram){
            payload.hasInstagram=this.state.hasInstagram
        }
        if(this.state.hasFacebook){
            payload.hasFacebook=this.state.hasFacebook
        }
        if(this.state.hasTwitter){
            payload.hasTwitter=this.state.hasTwitter
        }
        if(this.state.hasTiktok){
            payload.hasTiktok=this.state.hasTiktok
        }
        if(this.state.marketingAgreement){
            payload.marketingAgreement=this.state.marketingAgreement
        }
        
        const fd=new FormData()
        fd.append('stringified', JSON.stringify(payload))
        this.props.getUserSearchList(fd, this.usersFetched)
    }

    usersFetched = (data) => {
        console.log('usersFetched', data)
        if (!data.error) {
            this.setState({ data: data })
        }

    }

    handleGenderFilterChange = (e, key, value) => {
        console.log('typeof', typeof (parseInt(key)))
        let gendersState = JSON.parse(JSON.stringify(this.state.genders))
        let index = gendersState.findIndex(x => x === parseInt(key));
        if (index != -1) {
            gendersState.splice(index, 1)
        }
        if (e.target.checked === true) {
            gendersState.push(parseInt(key))
        }
       
        this.setState({ genders: gendersState, ['genderChecked' + key]: e.target.checked },
            () => {
                // console.log(this.state)
                this.fetch()
            }

        )
    }

    renderGenderFilters = () => {
        let checks = []
        for (const [key, value] of Object.entries(genders)) {
            checks.push(
                <Form.Check
                    key={'genderCheck' + key}
                    inline
                    label={value}
                    name={value}
                    value={this.state['genderChecked' + key]}
                    type={'checkbox'}
                    onChange={(e) => this.handleGenderFilterChange(e, key, value)}
                    id={'henderCheck' + key}
                    checked={this.state['genderChecked' + key] === true ? true : false}
                    disabled={this.state.fetching}
                />
            )
        }
        return (
            checks.map((item, index) => {
                return item
            })
        )
    }

    handleIsVerifiedRadio=(value)=>{
        this.setState({isVerified:value},()=>{
            this.fetch()
        })
    }

    handleCheckboxChange=(e)=>{
        this.setState({[e.target.name]:e.target.checked},()=>{
            this.fetch()
        })

    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Col xs={12} className='p-0'>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>Lista Uzytkownikow</h3>
                    </Col>
                    <Col xs={12} className='text-center upperCase py-3'>
                        {this.renderGenderFilters()}
                        </Col>

                        <Col xs={12} className='p-2 border text-center'>
                            <Form.Check
                                    inline
                                    label="Wszyscy"
                                    name="isVerified"
                                    value={0}
                                    type={'radio'}
                                    onClick={()=>this.handleIsVerifiedRadio('all')}
                                    id={'isVerifiedRadio0'}
                                    checked={this.state.isVerified==='all'}
                                    disabled={this.state.fetching}
                                />
                                <Form.Check
                                    inline
                                    label="Fani"
                                    name="isVerified"
                                    value={0}
                                    type={'radio'}
                                    onClick={()=>this.handleIsVerifiedRadio(0)}
                                    id={'isVerifiedRadio1'}
                                    checked={this.state.isVerified===0}
                                    disabled={this.state.fetching}
                                />
                                 <Form.Check
                                    inline
                                    label="Oczekujący na weryfikacje"
                                    name="isVerified"
                                    value={this.state.fans}
                                    type={'radio'}
                                    onClick={()=>this.handleIsVerifiedRadio(1)}
                                    id={'isVerifiedRadio2'}
                                    checked={this.state.isVerified===1}
                                    disabled={this.state.fetching}
                                />
                                <Form.Check
                                    inline
                                    label="Twórcy"
                                    name="isVerified"
                                    value={this.state.fans}
                                    type={'radio'}
                                    onClick={()=>this.handleIsVerifiedRadio(2)}
                                    id={'isVerifiedRadio3'}
                                    checked={this.state.isVerified===2}
                                    disabled={this.state.fetching}
                                />
                        </Col>
                        <Col xs={12} className='p-2 border text-center'>
                            <Form.Check
                                    inline
                                    label="Ma Instagram"
                                    name="hasInstagram"
                                    value={0}
                                    type={'checkbox'}
                                    onClick={(e)=>this.handleCheckboxChange(e)}
                                    id={'socialCheckbox1'}
                                    checked={this.state.hasInstagram}
                                    disabled={this.state.fetching}
                                />
                             <Form.Check
                                    inline
                                    label="Ma Twittera"
                                    name="hasTwitter"
                                    value={0}
                                    type={'checkbox'}
                                    onClick={(e)=>this.handleCheckboxChange(e)}
                                    id={'socialCheckbox2'}
                                    checked={this.state.hasTwitter}
                                    disabled={this.state.fetching}
                                />
                                 <Form.Check
                                    inline
                                    label="Ma Facebook"
                                    name="hasFacebook"
                                    value={0}
                                    type={'checkbox'}
                                    onClick={(e)=>this.handleCheckboxChange(e)}
                                    id={'socialCheckbox3'}
                                    checked={this.state.hasFacebook}
                                    disabled={this.state.fetching}
                                />
                                 <Form.Check
                                    inline
                                    label="Ma TikTok"
                                    name="hasTiktok"
                                    value={0}
                                    type={'checkbox'}
                                    onClick={(e)=>this.handleCheckboxChange(e)}
                                    id={'socialCheckbox4'}
                                    checked={this.state.hasTiktok}
                                    disabled={this.state.fetching}
                                />
                        </Col>
                        <Col xs={12} className='p-2 border text-center'>
                            <Form.Check
                                    inline
                                    label="Zgoda Marketingowa"
                                    name="marketingAgreement"
                                    value={0}
                                    type={'checkbox'}
                                    onClick={(e)=>this.handleCheckboxChange(e)}
                                    id={'socialCheckbox1'}
                                    checked={this.state.marketingAgreement}
                                    disabled={this.state.fetching}
                                />
                                </Col>
                </Row>
                {this.state.fetching &&
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                }
                {this.state.data &&
                    <Row noGutters>
                        <Col xs={12}>
                            {this.state.data && this.state.data.length === 0 &&
                                <p>Lista jest pusta.</p>
                            }
                            {this.state.data && this.state.data.length > 0 &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" ></th>
                                            <th scope="col" className='text-left'>Sociale</th>
                                            <th scope="col" className='text-center'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((item, index) => {
                                            console.log(item)
                                            return (
                                                <tr key={'tiItem' + index} className='textBlueGrey capitalize '>
                                                    <td className="strong text-left">
                                                        <Link to={'/user/' + item.user_id + '/'} target={'_blank'}>
                                                            <Image roundedCircle height={30} src={item.avatar} />  {item.displayName}
                                                        </Link>
                                                    </td>
                                                    <td className=" text-left py-4">
                                                      insta:  {item.instagram?<a href={'https://www.instagram.com/'+item.instagram} target='_blank'>{item.instagram}</a>:'Brak'} <br></br>
                                                      twitter:  {item.twitter?<a href={'https://www.twitter.com/'+item.twitter} target='_blank'>{item.twitter}</a>:'Brak'} <br></br>
                                                      FB:  {item.facebook?<a href={'https://www.facebook.com/'+item.facebook} target='_blank'>{item.facebook}</a>:'Brak'}  <br></br>
                                                      TikTok:  {item.tiktok?item.tiktok:'Brak'}<br></br>
                                                      
                                                      
                                                     
                                                    </td>
                                                    <td className=" text-right py-4">
                                                        
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                        </Col>
                    </Row>
                }

            </Col>
        )
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getUserSearchList,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersSearch);

