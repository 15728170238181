import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Oval } from 'react-loader-spinner'

import AgoraRTC from "agora-rtc-sdk-ng"
import {
    getBroadcastsTokens,
} from "../../actions/index";
const isMobile = false
export default function AdminPlayer(props) {
    const dispatch = useDispatch()
    const client = useRef();
    const playerRef = useRef();
    const remoteTracks = useRef({});
    const connectionStateRef = useRef('INITIAL')
    
    const [playerConfig, setPlayerConfig] = useState({})
    const [connectionState, setConnectionState] = useState('INITIAL')
    const [tokenFetchng, setTokenFetchng] = useState(false)
    const [audioMuted, setAudioMuted] = useState()
    const [videoMuted, setVideoMuted] = useState()
    const [playerId, setPlayerId] = useState((Math.random() + 1).toString(36).substring(7))

    useEffect(() => {
        start()
    }, [])
    useEffect(() => {
        if (props.data) {
            //  start()
        }
        else {
            //  stop()
        }

    }, [props.data])

    const start = async () => {

        // setFetching(true)
        let codecks = await AgoraRTC.getSupportedCodec()
        codecks = codecks.video
        let codec = "h264"
        if (codecks.includes("h264")) {
            codec = "h264"
        }
        else if (codecks.includes("vp9")) {
            codec = "vp9"
        }
        else if (codecks.includes("vp8")) {
            codec = "vp8"
        }
        client.current = AgoraRTC.createClient({ mode: "live", codec: codec })
        client.current.setClientRole('audience');
        client.current.on("connection-state-change", (data) => {
            console.log('cam2cam client.on  connection-state-change: ', data)
            if (connectionStateRef.current === 'CONNECTED' && data === 'DISCONNECTED') {
                //props.onHide()
            }
            setConnectionState(data)
            connectionStateRef.current = data
        });

        client.current.on("token-privilege-will-expire", () => {
            console.log('cam2cam token-privilege-will-expire client', client)
            if (!tokenFetchng) {
                connect()
            }
        });
        client.current.on("token-privilege-did-expire", () => {
            console.log('cam2cam token-privilege-did-expire')
            //wylaczamy player
        });
        connect()
    }
    const connect = async () => {
        console.log('connect props.rtcpProps', props.rtcpProps)
        // setTokenFetchng(true)
        //    dispatch(getBroadcastsTokens(props.broadcastId, tokenFetched))
        await client.current.leave()

        client.current.join(props.rtcpProps.appid, props.rtcpProps.channel, props.rtcpProps.token, props.rtcpProps.uid || null)
            .then((uid) => {
                // console.log('joined', uid)
                client.current.on("user-published", handleUserPublished);
                client.current.on("user-unpublished", handleUserUnpublished);
            }).catch((err) => {
                console.error(err)
            });
    }

    const handleUserPublished = (user, mediaType) => {
        //   console.log('handleUserPublished')
        subscribe(user, mediaType);
    }

    const handleUserUnpublished = (user) => {
        //  console.log('handleUserUnpublished user', user)
        //  console.log('handleUserUnpublished trackId', this.props.cameraTrack.trackId)
        // console.log('handleUserUnpublished user._video_added_)', user._video_added_)
        if (!user._video_added_) {
            leave()
        }
    }

    const subscribe = async (user, mediaType) => {
        console.log('subscribe user, mediaType', user, mediaType)
        const uid = user.uid;
        await client.current.subscribe(user, mediaType);
       // let _remoteTracks = Object.assign({}, remoteTracks)
        let _remoteTracks ={}
        if (mediaType === 'video') {
            _remoteTracks.videoTrack = user.videoTrack
            await user.videoTrack.play("remote-player" + playerId);
            
        }
        if (mediaType === 'audio') {
            _remoteTracks.audioTrack = user.audioTrack
            await user.audioTrack.play();
        }
        else {
            setAudioMuted(true)
        }
        remoteTracks.current = _remoteTracks
       
        setTimeout(()=>{
            console.log('remoteTracks.current',remoteTracks.current)
            reloadPlayerConfig()
        }, 500)
        
    }
    const leave = async () => {
        await client.current.leave();
        //  props.onHide()
    }

    const getScreenOrientation = () => {
        let screenOrientation = 'horizontal'
        let orientation = window.screen.orientation.type
        if (orientation === 'landscape-primary' || orientation === 'landscape-secondary') {
            screenOrientation = 'horizontal'
        }
        else if (orientation === 'portrait-primary' || orientation === 'portrait-secondary') {
            screenOrientation = 'vertical'
        }
        return screenOrientation
    }
   const calculatePlayerConfig = () => {
        let currentWidth = playerRef.current.offsetWidth
        let cameraSettings;
        let stats = remoteTracks.current.videoTrack._mediaStreamTrack.getSettings();
        if (stats.height && stats.width) {
            cameraSettings = { height: stats.height, width: stats.width }
        }
        else {
            stats = remoteTracks.current.videoTrack.getStats()
            if (stats.receiveResolutionWidth && stats.receiveResolutionHeight) {
                cameraSettings = { height: stats.receiveResolutionHeight, width: stats.receiveResolutionWidth }
            }
            else {
                setTimeout(() => {
                    calculatePlayerConfig(currentWidth)
                }, 200)
                return
            }
        }
        if (cameraSettings.height === 0 || cameraSettings.width === 0) {
            setTimeout(() => {
                this.calculatePlayerConfig(currentWidth)
            }, 200)
            return
        }
        let cameraWidth
        let cameraHeight
        if (cameraSettings.width && cameraSettings.height) {
            cameraWidth = cameraSettings.width
            cameraHeight = cameraSettings.height
        }
        else {


        }

        if (!cameraWidth || !cameraHeight) {

            // setTimeout(() => {
            //   this.calculatePlayerConfig(currentWidth)
            //  }, 200)
            // return
        }

        let aspectRatio = cameraWidth / cameraHeight
        let calculatedHeight = Math.floor(currentWidth / aspectRatio)
        let calculatedWidth = currentWidth
        let cameraOrientation;
        let screenOrientation;


        let isFullscreeen;
        let videoAlign = 'mx-auto'
        let fullScreenButton
        let oldScreenOrientation = playerConfig.screenOrientation
        if (cameraWidth > cameraHeight) {
            cameraOrientation = 'horizontal'
        }
        else {
            cameraOrientation = 'vertical'
        }
        //tu podmienic 
        screenOrientation = getScreenOrientation()
        //   if (window.innerWidth > window.innerHeight) {
        //      screenOrientation = 'horizontal'
        //  }
        //  else {
        //      screenOrientation = 'vertical'
        //   }
        if (oldScreenOrientation !== screenOrientation) {
            //orientationChange event
            //  const event = new CustomEvent("rotationChange", { detail: { screenOrientation: screenOrientation } });
            //  window.dispatchEvent(event);
        }
        if (cameraOrientation === 'vertical' && screenOrientation === 'horizontal') {
            if (calculatedHeight > window.innerHeight) {
                calculatedHeight = window.innerHeight - 50
                calculatedWidth = calculatedHeight * aspectRatio
                // pion na poziomym ekranie. Przeliczyc
            }
        }
        else {
            calculatedWidth = '100%'
        }
        if (document.fullscreenElement) {
            isFullscreeen = true
        }
        else {
            isFullscreeen = false
        }

        if (cameraOrientation === 'vertical' && screenOrientation === 'horizontal') {
            //  controlBarPos = 'side'
            //  tipBarPos = 'bottom'
            videoAlign = 'ms-auto'
            fullScreenButton = 'bottomRight'
            if (!isMobile) {
                calculatedHeight = window.innerHeight - 200
                calculatedWidth = calculatedHeight * aspectRatio
                //      channelInfoContFluid = false
            }
        }

        if (cameraOrientation === 'vertical' && screenOrientation === 'vertical') {
            // controlBarPos = 'side'
            //  tipBtnsPos = 'sideBar'
            fullScreenButton = 'insideBar'
        }
        if (cameraOrientation === 'horizontal' && screenOrientation === 'vertical') {
            //  controlBarPos = 'side'
            //  tipBarPos = 'bottom'
            fullScreenButton = 'insideBar'
        }

        if (cameraOrientation === 'horizontal' && screenOrientation === 'horizontal') {
            //   tipBarPos = 'bottom'
            fullScreenButton = 'bottomRight'
        }


        if (calculatedHeight > window.innerHeight - 40) {
            // if (!isFullscreeen) {
            calculatedHeight = window.innerHeight - 40//wysokosc headera
            calculatedWidth = calculatedHeight * aspectRatio
            //  }

        }
        if (window.innerWidth < 874) {
            //   tipBarPos = 'sideBar'
        }

        if (window.innerWidth < 767) {
            if (cameraOrientation === 'horizontal') {
                //   chatContPos = 'overSide'
                //   goalWidgetPos = 'bottomRight'
                fullScreenButton = 'insideBar'
            }
            else {
                //  chatContPos = 'over'
                //   goalWidgetPos = 'topLeft'
            }
        }
        if (window.innerWidth < 650) {
            if (cameraOrientation === 'horizontal' && screenOrientation === 'vertical') {
                //  chatContPos = 'side'
                //   goalWidgetPos = 'bottomLeft'
                fullScreenButton = 'insideBar'
                //  bellowTabs = true
                //   streamDescPos = 'inTab'
                if (!isFullscreeen) {
                    //   goalDescPosition = 'bottom'
                }
            }

        }
        //console.log('chatContPos', chatContPos)
        //     <Col key={'StreamerPlayer' + index} className={'' + (this.props.isEnlarged? 'order-md-2' : 'order-md-1') + (this.props.cameraTracksCount > 1 ? ' multiCamBorder' : '')} xs={cameraTracksCount === 1 ? 12 : this.props.isEnlarged ? (this.props.isEnlarged? 12 : 3) : 6}>

        //console.log('calculatePlayerConfig',cameraOrientation,screenOrientation,  calculatedWidth, calculatedHeight)
        calculatedWidth = '100%'
        return {
            height: calculatedHeight,
            width: calculatedWidth,
            cameraWidth: cameraWidth,
            cameraHeight: cameraHeight,
            aspectRatio: aspectRatio,
            cameraOrientation: cameraOrientation,
            screenOrientation: screenOrientation,
            //controlBarPos: controlBarPos,
            // chatContPos: chatContPos,
            // tipBarPos: tipBarPos,
            videoAlign: videoAlign,
            //  bellowTabs: bellowTabs,
            fullScreenButton: fullScreenButton,
            //  showFullscreenRotateInfo: showFullscreenRotateInfo,
            //  streamDescPos: streamDescPos,
            isFullscreeen: isFullscreeen,
            //   goalWidgetPos: goalWidgetPos,
            //    goalDescPosition: goalDescPosition,
            //    channelInfoContFluid: channelInfoContFluid,
            //colWidth:colWidth,
            // colClass:colClass,

        }
    }

    const reloadPlayerConfig = () => {
         console.log('setPlayerConfig playerRef.current', playerRef.current)
        if(!playerRef.current){
            setTimeout(()=>{
                reloadPlayerConfig()
            }, 1000)
            return
        }
        
        let currentWidth = playerRef.current.offsetWidth
        let playerConfig = calculatePlayerConfig(currentWidth)
        setPlayerConfig(playerConfig)
    }

    const muteAudio = async (check = true) => {
        if (!remoteTracks.current.audioTrack) {
            return
        }

        try {
            await remoteTracks.current.audioTrack.setMuted(true);
            if (this.state.remoteTracks.audioTrack) {
                await remoteTracks.current.audioTrack.setVolume(0);
            }
            setAudioMuted(true)
        } catch (e) {
            console.log(e)
        }
    }

    const unmuteAudio = async () => {
        if (!remoteTracks.current.audioTrack) {
            return
        }
        try {
            await remoteTracks.current.audioTrack.setVolume(100);
            setAudioMuted(false)
        } catch (e) {
            console.log(e)
        }
    }

    
    return (
        
        <div id={"remote-player" + playerId}
            // onClick={this.props.cameraTracksCount > 1 ? (e) => this.handlePlayerOnClick(e) : () => null}
            ref={playerRef}
            className={"player "}
            style={{ width: playerConfig.width, height: playerConfig.height, position: 'relative' }}
        >
            {connectionState === 'CONNECTING' &&

                    <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
            }
        </div>
    )
}