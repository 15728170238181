import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    addAdminNotes,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
class NotatkiBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        // this.setState({ reason:this.props.inSuspected.reason })
    }
    state = {
        fetching: false,
        note: '',
        show: false,
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }


    saveForm = () => {
        toast.dismiss()
        if (this.state.note.length === 0) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz podać treść notatki!') })
            return
        }
        this.setState({ fetching: true })
        const fd = new FormData()
        fd.append('note', this.state.note)
        this.props.addAdminNotes(this.props.user.id, fd, this.formSaved)
    }

    formSaved = (data) => {
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ note: '', showForm: false, show: true })
            toast.success((t) => { return this.renderToastContent(t, 'Notatka została dodana.') })
        }
        else {
            let msg = ''
            if (data.data.message) {
                msg = data.data.message
            }
            else {
                msg = data.data
            }
            toast.error((t) => { return this.renderToastContent(t, msg) })
        }
    }
    renderNotes = () => {
        return (
            <ListGroup>
                {this.props.adminNotes.map((item, index) => {
                    return (
                        <ListGroup.Item key={'notatka' + index}>
                            <small>
                                <div>{item.note}</div>
                                <div className='text-black-50'>
                                    {item.date} {item.admin.displayName}
                                </div>
                            </small>
                        </ListGroup.Item>
                    )
                })
                }
            </ListGroup>
        )

    }
    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3'>
                    <Toaster />
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>

                            <Row noGutters>
                                <Col xs={12} className='text-center pb-1'>
                                    <h5>NOTATKI:</h5>
                                </Col>
                                <Col onClick={() => { this.setState({ show: !this.state.show }) }} xs={12} className='text-center pb-2' >
                                    {this.state.show &&
                                        <span className="text-primary cursor">Ukryj<AiOutlineUp /></span>
                                    }
                                    {!this.state.show &&
                                        <span className="text-primary cursor">Pokaż<AiOutlineDown /></span>
                                    }
                                </Col>
                            </Row>
                            {this.state.show &&
                                <Row noGutters>
                                    <Col onClick={() => { this.setState({ showForm: !this.state.showForm }) }} xs={12} className='text-center pb-2'>
                                        <span className="text-primary cursor">Dodaj notatke+</span>
                                    </Col>
                                </Row>
                            }
                            {this.state.showForm && this.state.show &&
                                <Row noGutters>
                                    <Col xs={10} md={6} className='mx-auto text-center pb-3'>
                                        <Form.Control
                                            as="textarea"
                                            type="textarea"
                                            placeholder="Napisz notatkę"
                                            value={this.state.reason}
                                            onChange={(e) => { this.setState({ note: e.target.value }) }}
                                            disabled={this.state.fetching}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Row noGutters>
                                            <Col xs={10} md={3} className='mx-auto text-center pb-3'>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => { this.saveForm() }}
                                                    disabled={this.state.fetching}
                                                >
                                                    ZAPISZ
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                            {this.state.show &&
                                <Row noGutters>
                                    <Col xs={12} md={5} className='mx-auto text-left' style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                        {this.renderNotes()}
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        adminNotes: state.user.adminNotes,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                addAdminNotes,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotatkiBox);