import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { GiTwoCoins } from 'react-icons/gi'

export default function StreamChatWrapper(props) {
    
    
    const renderChatMessages = () => {
        let renderedMessages = props.chatContent.map((item, index) => {
          let avatar = item.avatar
          let isHost = ''
          if (item.isHost) {
            isHost = 'isHost'
          }
         
          if (item.type === 'NEW_MESSAGE') {
            return (
              <Row noGutters key={'chatMsg' + index}>
                <Col xs={12} className='py-2 px-2 chatMessageCont'>
                  <Row noGutters>
                    <Col xs={'auto'} className='pr-2'>
                      <Image src={avatar} height={25} roundedCircle className={'chatAvatar '} />
                    </Col>
                    <Col>
                      <span className={'chatMsgDisplayName ' + isHost}>{item.displayName}</span>: <span className={'chatMsgMsg ' + isHost}>{item.message}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
          else if (item.type === 'USER_JOINED') {
            return (
              <Row noGutters key={'chatMsg' + index}>
                <Col xs={12} className=''>
                  <Row noGutters>
                    <Col xs={'auto'} className='chatNotsCont'>
                      <span className='chatNotsDisplayName'>{item.displayName}</span> <span className='chatNotsTxt'>dołączył</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
          else if (item.type === 'USER_LEFT') {
            return (
              <Row noGutters key={'chatMsg' + index}>
                <Col xs={12} className=''>
                  <Row noGutters>
                    <Col xs={'auto'} className='chatNotsCont'>
                      <span className='chatNotsDisplayName'>{item.displayName}</span> <span className='chatNotsTxt'>left</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
          else if (item.type === 'NEW_TIP') {
            return (
              <Row noGutters key={'chatMsg' + index}>
                <Col xs={12} className=''>
                  <Row noGutters>
                    <Col xs={'auto'} className='chatNotsCont tip'>
                      <span className='chatNotsDisplayName'>{item.displayName}</span>: <span className='chatNotsTxt'>Wysłał {item.amount}</span> <GiTwoCoins className='yelloGold' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
        })
        return renderedMessages
      }

return(
    <div className='chatMdInnerCont'
  //  ref={this.chatContRef}
 //   style={{ fontSize: this.state.fontSize + 'px' }}

  >
    {renderChatMessages()}
    </div>
)

}