import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    addToWatched,
    removeFromWatched,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import toast, { Toaster } from 'react-hot-toast';

class InWatchedBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {

    }
    state={
        inWatchedFetching:false,
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    
    handleInWatched = (inWatched) => {
        this.setState({ inWatched: inWatched, inWatchedFetching: true })
        if (inWatched) {
            this.props.addToWatched(this.props.user.id, this.watchedFetched)
        }
        else {
            this.props.removeFromWatched(this.props.user.id, this.watchedFetched)
        }
    }

    watchedFetched = (data) => {
        this.setState({ inWatchedFetching: false })
        if (!data.error) {
            toast.success((t) => { return this.renderToastContent(t, data.message) })
        }
        else {
            let msg=''
            if(data.data.message){
                msg=data.data.message
            }
            else{
                msg=data.data
            }
            toast.error((t) => { return this.renderToastContent(t, msg) })
        }

    }
    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3'>
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>
                            <Row noGutters>
                                <Col xs={12} className='text-center pb-2'>
                                    <span style={{ marginRight: '15px' }}>Profil w obserwacji:</span>
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'inWatched'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => this.handleInWatched(true)}
                                        id={'inWatchedRadio'}
                                        checked={this.props.inWatched}
                                        disabled={this.state.inWatchedFetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'inWatched'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => this.handleInWatched(false)}
                                        id={'inWatchedRadio'}
                                        checked={this.props.inWatched ? false : true}
                                        disabled={this.state.inWatchedFetching}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
       // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                addToWatched,
                removeFromWatched,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InWatchedBox);