import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getPromoCommisionRequests, } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';

import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import DialogToast from "../components/dialogToast";
import LoaderAbsoluteOverlay from "../components/loaderAbsoluteOverlay";
import Form from 'react-bootstrap/Form';

class PromoCommisionView extends Component {
    componentDidMount() {
        this.fetchData()
    }
    state = {
        filter: 'pending'
    }

    fetchData = () => {
        this.setState({ fetching: true })
        this.props.getPromoCommisionRequests(this.state.filter, this.dataFetched)
    }
    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ data: data })
        }

    }
    renderRequestItem = (item) => {
        let status = ''
        if (item.status === 'pending') {
            status = 'Oczekuje na akceptację'
        }
        if (item.status === 'accepted') {
            status = 'Zaakceptowano'
        }
        if (item.status === 'declined') {
            status = 'Odrzucono'
        }
        return (
            <Col xs={12} md={6} className="border p-3 pb-3">
                <Row>
                    <Col xs={12} className='text-center'>
                        <Image src={item.blogger.avatar} roundedCircle style={{ width: '100px', height: '100px' }} /><br></br>
                        <span className='capitalize strong'>
                            <Link to={'/user/' + item.blogger.userId} target='_blank'>{item.blogger.displayName}</Link>
                        </span>
                    </Col>
                    <Col xs={12} className='text-left'>
                        <span className='strong'>Status </span>
                        <span>{status}</span>
                    </Col>
                    {item.acceptedBy &&
                    <>
                        <Col xs={12} className='text-left'>
                            <span className='strong'>Rozpatrzony przez:  </span>
                            <span>{item.acceptedBy.displayName}</span>
                        </Col>
                        <Col xs={12} className='text-left'>
                            <span className='strong'>Data rozpatrzenia:  </span>
                            <span>{item.aacceptedTime}</span>
                        </Col>
                        </>
                    }
                    <Col xs={12} className='text-left pt-3'>
                        <span className='strong'>Social link: </span>
                        <span>{item.socialMediaLink}</span>

                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <span className='strong'>Utworzony: </span>
                        <span>{item.created}</span>
                    </Col>
                    <Col xs={12} className='text-center my-3'>
                            <Link to={'/user/' + item.blogger.userId} target='_blank' className='btn btn-danger'> PRZEJDZ DO ZARZĄDZANIA</Link>
                    </Col>
                </Row>
            </Col>
        )
        return (
            <tr key={item.id}>
                <td>{item.blogger.displayName}</td>
                <td>{item.socialMediaLink}</td>
                <td>{item.status}</td>
                <td>{item.created}</td>
            </tr>
        )
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }

        return (
            <Container>
                <Row noGutters >
                    <Col xs={12} className='text-center upperCase py-3 px-0'>
                        <h3>PROMOCJA PROWIZJI</h3>
                    </Col>
                </Row>
                {this.state.fetching &&
                    <Row noGutters>
                        <Col xs={12} className='text-center mt-5'>
                            <div className='mx-auto' style={{ width: '125px', height: '125px' }}>
                                <Oval className='mx-auto' color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                            </div>
                        </Col>
                    </Row>
                }
                {this.state.data && !this.state.fetching && this.state.data.length == 0 &&
                    <Row noGutters>
                        <Col xs={12} className='text-center mt-5'>
                            <h5>Brak danych</h5>
                        </Col>
                    </Row>
                }
                {this.state.data && this.state.data.length > 0 &&
                    <Row>
                        {this.state.data.map((item, index) => {
                            return this.renderRequestItem(item)
                        })}
                    </Row>

                }
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getPromoCommisionRequests,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCommisionView);