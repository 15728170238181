import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getUserPayoutsList,
    getInvoicePdf
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Table from 'react-bootstrap/Table';

class PayoutsBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        this.nextPage()
    }

    state = {
        fetching: false,
        page: 1,
        hasMorePages: true,

    }
    nextPage = () => {
        if (!this.state.fetching && this.state.hasMorePages) {
            this.setState({ fetching: true })
            this.props.getUserPayoutsList(this.props.user.id, this.state.page, this.dataFetched)
        }
    }
    dataFetched = (data) => {
        console.log('PayoutsBox dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            if (this.state.data) {
                let oldData = JSON.parse(JSON.stringify(this.state.data))
                data = [...oldData, ...data]
                console.log('new dataFetched data', data)
            }
            else {
                data = data
            }
            this.setState({ data: data, page: this.state.page + 1 })
        }
        else {
            this.setState({ hasMorePages: false })
        }
    }
    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    showDetails = (item) => {
        console.log('showDetails', item)
        this.setState({ redirect: '/zarobki/details/' + item.id + '/' })
        ///zarobki/details/:id/
    }
    renderList = () => {
        return (
            this.state.data.map((item, index) => {

              //  console.log(item)
                //1- abonament, 2- tip, 3-referral program, 4 - ppv, 5 - snapy
                let status = ''
                if (item.payoutStatus === 1) {
                    status = 'Oczekuje'
                }
                else if (item.payoutStatus === 2) {
                    status = <span className='text-success'>Zapłacona</span>
                }
                if (item.paused) {
                    status = <span className='text-danger'>Zablokowana</span>
                }


                return (
                    
                        <tr key={'wyplatytem' + index} className='cursor'>
                            <td className="cursor" >
                                {item.createDime}
                            </td>
                            <th scope="row" className="capitalize">
                                <strong>
                                <Link to={'/zarobki/details/' + item.id + '/'} target='_blank'>  {item.kwota}{item.waluta} </Link>
                                </strong>
                            </th>
                            <td className="">
                                {status}
                            </td>
                            <td>
                                <button onClick={()=>{this.generatePdf(item.id)}}>PDF</button>
                            </td>
                        </tr>
                )
            })
        )
    }

    chatDetailsOnScroll = (e) => {
        // console.log(e)
        //find when scroll is on top
        let scrollPosition = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
        // console.log('scrollPosition', scrollPosition)
        if (scrollPosition < 100) {
            this.nextPage()
        }

    }
    generatePdf=(wyplataId)=>{
        console.log('wyplataId', wyplataId)
        getInvoicePdf(wyplataId, this.pdfGenerated)
    }

    pdfGenerated=(data)=>{
        console.log('pdfGenerated', data)
        if(!data.error){

        }
        else{
            alert(data.error.message)
        }
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3 text-center'>
                    <Toaster />
                    <h5>WYPŁATY:</h5>
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3 '>
                            <Row noGutters>
                                {this.state.data && this.state.data.length === 0 &&
                                    <Col xs={12} className='text-center pb-2'>
                                        Brak wypłat
                                    </Col>
                                }
                                {this.state.data && this.state.data.length > 0 &&
                                    <Col xs={12} className='text-center ' style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                        <div className="kupioneListCont" onScroll={this.chatDetailsOnScroll}>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" >Data</th>
                                                        <th scope="col" >Kwota</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">PDF</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {this.renderList()}
                                                </tbody>
                                            </Table>
                                            {!this.state.hasMorePages &&
                                                <Row noGutters>
                                                    <Col xs={12} className='text-center py-2'>
                                                        Koniec listy.
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </Col>
                                }
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getUserPayoutsList,
              //  getInvoicePdf,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutsBox);