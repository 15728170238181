import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getUsersCount=(fd, callBack) =>{
    let path=ApiUrl+"userslist/count/"
    console.log('getUsersCount path', path)
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('getUsersCount response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getUsersCount error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
  }

  export const postAlerts=(fd, callBack) =>{
    let path=ApiUrl+"alerts/"
    console.log('postAlerts path', path)
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('postAlerts response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('postAlerts error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
  }

  export const sendMassMail=(fd, callBack) =>{
    console.log('sendMassMail')
    let path=ApiUrl+"massmail/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('sendMassMail',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const sendmailgunBulkMailing=(fd, callBack) =>{
    console.log('sendmailgunBulkMailing')
    let path=ApiUrl+"mailing/mailgunbulkmailing/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('sendmailgunBulkMailing',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }
  