
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getPayoutDetails, editPayoutDetails, getPayoutsList, getInvoicePdf } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { sepaCountries } from '../constants/sepaCountries'
class PayoutDetailsView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    state = {
        payoutId: undefined,
        sendMail: true,
        payoutStatusFilter: 1,
        pausedFilter: 'unpaused',
        hideUnknown: true,
    }

    search = () => {
        const fd = new FormData()
        if (this.state.payoutId) {
            fd.append('payoutId', this.state.payoutId.trim())
        }
        if (this.state.payoutStatusFilter !== 'all') {
            fd.append('payoutStatus', this.state.payoutStatusFilter)
        }
        if (this.state.pausedFilter !== 'all') {
            fd.append('paused', this.state.pausedFilter)
        }
        if (this.state.hideUnknown) {
            fd.append('hideUnknown', this.state.hideUnknown)
        }
        this.setState({ listDataFetching: true, listData: undefined })
        getPayoutsList(fd, this.listDataFetched)
    }
    calculteSum = () => {
        let sum = 0;
        this.state.listData.forEach(element => {
            sum += parseFloat(element.kwota);
        });
        this.setState({ sum: sum.toFixed(2) })
    }
    listDataFetched = (data) => {
        console.log('listDataFetched', data)
        this.setState({ listDataFetching: false })
        if (!data.error) {
            this.setState({ listData: data })
            this.calculteSum()
        }

    }

  
    getPayoutDetails = (payoutId) => {
        this.setState({ iban: undefined, ibanCountryCode: undefined, payoutDetails: undefined, detailsFetching: true })
        getPayoutDetails(payoutId, this.payoutDetailsFetched)
    }

    payoutDetailsFetched = (data) => {
        this.setState({ detailsFetching: false })
        console.log('payoutDetailsFetched', data)
        this.parseIban(data.payoutData.iban)
        this.setState({ payoutDetails: data })
    }
    
    parseIban = (iban) => {
        try {
            let ibanCountryCode = iban.slice(0, 2);
            let ibanNumber = iban.slice(2, iban.length);
            this.setState({ ibanCountryCode: ibanCountryCode })
            if (ibanCountryCode == 'PL') {
                this.setState({ iban: ibanNumber })
            }
            else {
                this.setState({ iban: iban })

            }
        } catch (e) {
            console.log('parseIban error', e)

            this.setState({ iban: 'Błąd! Brak nr IBAN', ibanCountryCode: '??' })
        }
        // ibanNumber = this.addSpacesToIban(ibanNumber)
    }
    getPayoutListStatus = (item) => {
        let status = ''
        let addClass = ''
        if (item.payoutStatus === 2) {
            addClass = 'text-success'
            status = 'Wypłacono'
        }
        if (item.payoutStatus === 1) {
            addClass = 'text-warning'
            status = 'Oczekuje'
        }
        if (item.paused) {
            addClass = 'text-danger'
            status = 'Zablokowana'
        }
        if (item.banned) {
            addClass = 'text-danger'
            status = ' (ZBANOWANA)'
        }
        return <span className={addClass}><strong>{status}</strong></span>
    }
    renderListItem = (item, index) => {
        //bloggerDisplayName, bloggerUrlName, createDime, kwota, waluta,'paused', 'banned'
        return (
            <tr className='cursor' key={'listItem' + index} onClick={() => this.getPayoutDetails(item.id)}>
                <td>{index + 1}</td>
                <td className="text-left">{item.createDime}</td>
                <td className="capitalize">{item.bloggerDisplayName}</td>
                <td className="upperCase text-right"><strong>{item.kwota}{item.waluta}</strong></td>
                <td>{this.getPayoutListStatus(item)}</td>
            </tr>
        )
    }
    getPayoutStatus = () => {
        let status = ''
        let addClass = ''
        if (this.state.payoutDetails.payoutStatus === 2) {
            addClass = 'text-success'
            status = 'Wypłacono'
        }
        if (this.state.payoutDetails.payoutStatus === 1) {
            addClass = 'text-warning'
            status = 'Oczekuje'
        }
        if (this.state.payoutDetails.paused) {
            addClass = 'text-danger'
            status = ' Zablokowana'
        }
        if (this.state.payoutDetails.banned) {
            addClass = 'text-danger'
            status = ' (ZBANOWANA)'
        }
        //   if(this.state.payoutDetails.status==='rejected'){
        //     return 'Odrzucono'
        // }
        //  if(this.state.payoutDetails.status==='canceled'){
        //       return 'Anulowano'
        //   }
        //   if(this.state.payoutDetails.status==='failed'){
        //       return 'Nieudana'
        //   }
        return <span className={addClass}><strong>{status}</strong></span>
    }

    pausePayout = (status) => {
        this.setState({ payoutPauseFetching: true })
        console.log('pausePayout', status)
        const fd = new FormData()
        fd.append('action', status)
        editPayoutDetails(this.state.payoutDetails.id, fd, this.payoutPaused)

    }
    payoutPaused = (data) => {
        this.setState({ payoutPauseFetching: false })
        console.log('payoutPaused', data)
        if (!data.error) {
            toast.success('Zmieniono status blokady wypłaty')
            this.setState({ payoutDetails: data })
            this.payoutPauseModalOnHide()
        }
        this.updateListItem(data)
    }
    editPayoutStatus = (status) => {
        this.setState({ payoutStatusFetching: true })
        console.log('editPayoutStatus', status)
        const fd = new FormData()
        fd.append('action', status)
        if (this.state.sendMail) {
            fd.append('sendMail', '1')
        }

        editPayoutDetails(this.state.payoutDetails.id, fd, this.payoutStatusEdited)

    }

    updateListItem = (item) => {
        // make deep copy of this.state.listData
        let listData = JSON.parse(JSON.stringify(this.state.listData))
        //find item in listdata and replace with item
        let index = listData.findIndex((i) => i.id === item.id)
        listData[index] = item
        this.setState({ listData: listData })


    }
    payoutStatusEdited = (data) => {
        this.setState({ payoutStatusFetching: false })
        console.log('payoutStatusEdited', data)
        if (!data.error) {
            toast.success('Zmieniono status wypłaty')
            this.setState({ payoutDetails: data })
            this.payoutStatusModalOnHide()
            this.updateListItem(data)
        }
    }
    payoutPauseModalOnHide = () => {
        this.setState({ showPayoutPauseModal: false, payoutModalStatusType: undefined, sendMail: true })
    }
    showPayoutPauseModal = (type) => {
        this.setState({ showPayoutPauseModal: true, payoutModalStatusType: type })
    }
    payoutStatusModalOnHide = () => {
        this.setState({ showPayoutStatusModal: false })
    }
    renderPaymentDetails = () => {
        return (
            <Row noGutters>
                <Col xs={12} className='px-2 text-center mt-4 capitalize'>
                    <Link to={'/user/' + this.state.payoutDetails.bloggerId + '/'} target={'_blank'}>
                        <Image src={this.state.payoutDetails.bloggerAvatar} roundedCircle height={100} />
                    </Link>
                    <br></br>
                    <Link to={'/user/' + this.state.payoutDetails.bloggerId + '/'} target={'_blank'}>
                        <h5>
                            {this.state.payoutDetails.bloggerDisplayName}
                        </h5>
                    </Link>
                </Col>

                <Col xs={12} className='px-2 text-center pt-3 upperCase'>
                    KWOTA DO WYPŁATY: <br></br>
                    <h4>{this.state.payoutDetails.kwota} {this.state.payoutDetailswaluta}</h4>

                </Col>
                <Col xs={12} className='px-2 pt-3 text-center'>
                    DANE
                </Col>
                <Col xs={12} className='px-2  text-center capitalize'>
                    <h4>
                        {this.state.payoutDetails.payoutData.bankAccountName}<br></br>
                        {this.state.payoutDetails.payoutData.bankAccountSurname}
                    </h4>
                </Col>
                {this.state.ibanCountryCode !== 'PL' && this.state.ibanCountryCode !== undefined &&
                    <>
                        <Col xs={12} className='px-2 pt-3 text-center'>
                            BIC/SWIFT
                        </Col>
                        <Col xs={12} className='px-2 text-center'>
                            <h4>{this.state.payoutDetails.BIC}</h4>
                        </Col>
                        <Col xs={12} className='px-2 pt-3 text-center'>
                            KRAJ BANKU
                        </Col>
                        <Col xs={12} className='px-2 text-center'>
                            <h4>{sepaCountries[this.state.ibanCountryCode]}</h4>
                        </Col>
                    </>
                }
                <Col xs={12} className='px-2 pt-3 text-center'>
                    NR. KONTA:
                </Col>
                <Col xs={12} className='px-2 text-center'>
                    {!this.state.payoutDetails.paused &&
                        <h4>{this.state.iban}</h4>
                    }
                    {this.state.payoutDetails.paused &&
                        <h4 className="text-danger">WYPŁATA ZABLOKOWANA</h4>
                    }
                </Col>
                <Col xs={12} className='px-2 pt-3 text-center'>
                    TYTUŁEM:
                </Col>
                <Col xs={12} className='px-2 text-center'>
                    <h5>{this.state.ibanCountryCode === 'PL' ? 'Rozliczenie twórcy z serwisu Watchmemore. Id:' : 'Settlement of the creator of the Watchmemore service. Id:'}  {this.state.payoutDetails.payoutId}</h5>
                </Col>
                <Col xs={12} className='px-2 mt-3 text-center'>
                    <strong>UTWORZONA:</strong><br></br>
                    {this.state.payoutDetails.createDime}
                </Col>
                <Col xs={12} className='px-2 mt-1 text-center'>
                    <strong>STATUS:</strong><br></br>
                    {this.getPayoutStatus()}
                </Col>
                {this.state.payoutDetails.payoutStatus === 2 &&
                    <Col xs={12} className='px-2 mt-1 text-center'>
                        ZAPAŁACONA: <br></br>
                        {this.state.payoutDetails.payoutDate}
                    </Col>
                }

                {!this.state.payoutDetails.banned &&
                    <>
                        <Col xs={12} className='px-2 mt-3 text-center'>
                            <Button variant={this.state.payoutDetails.payoutStatus === 2 ? 'danger' : 'success'} onClick={() => { this.setState({ showPayoutStatusModal: true }) }}>
                                ZMIEŃ STATUS
                                {this.state.payoutDetails.payoutStatus === 1 && ' NA ZAPŁACONA'}
                                {this.state.payoutDetails.payoutStatus === 2 && ' NA NIEZAPŁACONA'}
                            </Button>
                        </Col>
                        {this.state.payoutDetails.payoutStatus === 1 &&
                            <Col xs={12} className='px-2 mt-3 text-center'>
                                {!this.state.payoutDetails.paused &&
                                    <Button variant={'warning'} onClick={() => { this.showPayoutPauseModal('pause') }}>
                                        ZABLOKUJ WYPŁATĘ
                                    </Button>
                                }
                                {this.state.payoutDetails.paused &&
                                    <Button variant={'danger'} onClick={() => { this.showPayoutPauseModal('unpause') }}>
                                        ODBLOKUJ WYPŁATĘ
                                    </Button>
                                }
                            </Col>
                        }
                    </>
                }
                <Col xs={12} className='px-2 mt-5'>
                    KWOTA DO WYPŁATY WG. TRANSAKCJI: {this.state.payoutDetails.calculatedPayout}
                </Col>



            </Row>
        )
    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Container>
                <Toaster />
                <Row noGutters>
                    <Col xs={6} className='border-right payoutsLeftWrapper d-flex align-items-start  flex-column'>
                        <Row noGutters >
                            <Col xs={12} className='text-center upperCase py-3 px-0'>
                                <h3>WYPŁATY</h3>
                            </Col>
                            <Col xs={12} className=' border'>
                                <Row noGutters>
                                    <Col className='text-center'><h5>WYSZUKAJ WYPŁATĘ:</h5></Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={12} className=' p-2 mx-auto'>
                                        <Row noGutters>
                                            <Col xs={12} className='text-center'>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="ID WYPŁATY"
                                                    value={this.state.payoutId}
                                                    onChange={(e) => { this.setState({ payoutId: e.target.value, data: undefined }) }}
                                                    disabled={this.state.fetching}
                                                />
                                            </Col>
                                            <Col xs={12} className='text-center pt-2'>
                                                <Form.Check
                                                    inline
                                                    label="Wszystkie"
                                                    name="payoutStatus"
                                                    type='radio'
                                                    id={`payoutStatusAll`}
                                                    checked={this.state.payoutStatusFilter === 'all'}
                                                    onChange={() => { this.setState({ payoutStatusFilter: 'all' }) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Zapłacone"
                                                    name="payoutStatus"
                                                    type='radio'
                                                    id={`payoutStatusPaid`}
                                                    checked={this.state.payoutStatusFilter === 2}
                                                    onChange={() => { this.setState({ payoutStatusFilter: 2 }) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Nie zapłacone"
                                                    name="payoutStatus"
                                                    type='radio'
                                                    id={`payoutStatusNotPaid`}
                                                    checked={this.state.payoutStatusFilter === 1}
                                                    onChange={() => { this.setState({ payoutStatusFilter: 1 }) }}
                                                />
                                            </Col>
                                            <Col xs={12} className='text-center pt-2'>
                                                <Form.Check
                                                    inline
                                                    label="Wszystkie"
                                                    name="pausedFilter"
                                                    type='radio'
                                                    id={`ppausedFilterAll`}
                                                    checked={this.state.pausedFilter === 'all'}
                                                    onChange={() => { this.setState({ pausedFilter: 'all' }) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Zablokowane"
                                                    name="pausedFilter"
                                                    type='radio'
                                                    id={`pausedFilterTrue`}
                                                    checked={this.state.pausedFilter === 'paused'}
                                                    onChange={() => { this.setState({ pausedFilter: 'paused' }) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Odblokowane"
                                                    name="pausedFilter"
                                                    type='radio'
                                                    id={`pausedFilterFalse`}
                                                    checked={this.state.pausedFilter === 'unpaused'}
                                                    onChange={() => { this.setState({ pausedFilter: 'unpaused' }) }}
                                                />
                                            </Col>
                                            <Col xs={12} className='text-center pt-2'>
                                                <Form.Check
                                                    inline
                                                    label="Ukryj Unknown"
                                                    name="hideUnknown"
                                                    type='checkbox'
                                                    id={`hideUnknown`}
                                                    checked={this.state.hideUnknown}
                                                    onChange={(e) => { this.setState({ hideUnknown: e.target.checked }) }}
                                                />
                                            </Col>
                                            <Col xs={12} className='text-center pt-2'>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => { this.search() }}
                                                    disabled={this.state.fetching}
                                                >
                                                    SZUKAJ
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className='payoutListWrapper px-0'>
                            {this.state.listDataFetching &&
                                <Row noGutters>
                                    <Col xs={12} className='text-center mt-5'>
                                        <div className='mx-auto' style={{ width: '125px', height: '125px' }}>
                                            <Oval className='mx-auto' color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Col >
                                <Table striped bordered hover >
                                    <tbody>
                                        {this.state.listData && this.state.listData.length > 0 && this.state.listData.map((item, index) => {
                                            return this.renderListItem(item, index)
                                        })}
                                    </tbody>
                                </Table>
                                {this.state.sum &&
                                    <div><strong>SUMA: {this.state.sum}</strong></div>
                                }
                            </Col>
                        </div>
                    </Col>
                    <Col xs={6} className=''>
                        <Row noGutters>
                            <Col xs={12} className='p-0'>

                                {this.state.detailsFetching &&
                                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center mt-5' style={{}}>
                                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                                    </Container>
                                }
                                {this.state.payoutDetails &&
                                    this.renderPaymentDetails()
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal

                    centered
                    show={this.state.showPayoutPauseModal}
                    onHide={() => this.payoutPauseModalOnHide()}
                    dialogClassName="modal-90h  flex-column"
                >
                    <Modal.Body className='p-0  '>
                        {this.state.payoutDetails &&
                            <Row noGutters className=''>
                                <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
                                    <h3>BLOKADA WYPŁATY</h3>
                                </Col>
                                <Col xs={12} className='text-center py-2'>
                                    <h5>{this.state.payoutDetails.payoutId}</h5>
                                </Col>
                                <Col xs={12} className='text-center py-2'>
                                    {this.state.payoutModalStatusType === 'pause' && 'Czy na pewno chcesz zablokować wypłatę?'}
                                    {this.state.payoutModalStatusType === 'unpause' && 'Czy na pewno chcesz odblokować wypłatę?'}
                                </Col>
                                <Col xs={12} className='text-center py-2'>
                                    {!this.state.payoutDetails.paused &&
                                        <Button variant={'danger'}
                                            onClick={() => { this.pausePayout('pause') }}
                                            disabled={this.state.payoutPauseFetching}
                                        >
                                            ZABLOKUJ WYPŁATĘ {this.state.payoutPauseFetching && <Spinner size={'sm'} />}
                                        </Button>
                                    }
                                    {this.state.payoutDetails.paused &&
                                        <Button variant={'danger'} onClick={() => { this.pausePayout('unpause') }} disabled={this.state.payoutPauseFetching}>
                                            ODBLOKUJ WYPŁATĘ{this.state.payoutPauseFetching && <Spinner size={'sm'} />}
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                </Modal >

                <Modal

                    centered
                    show={this.state.showPayoutStatusModal}
                    onHide={() => this.payoutStatusModalOnHide()}
                    dialogClassName="modal-90h  flex-column"
                >
                    <Modal.Body className='p-0  '>
                        {this.state.payoutDetails &&
                            <Row noGutters className=''>
                                <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
                                    <h3>STATUS WYPŁATY</h3>
                                </Col>
                                <Col xs={12} className='text-center py-2'>
                                    <h5>{this.state.payoutDetails.payoutId}</h5>
                                </Col>
                                {this.state.payoutDetails.paused &&
                                    <Col xs={12} className='text-center py-2'>
                                        <h5 className="text-danger">Wypłata jest zablokowana</h5>
                                    </Col>
                                }
                                {!this.state.payoutDetails.paused &&
                                    <>
                                        <Col xs={12} className='text-center py-2'>
                                            <h5 className="text-danger">Czy napewno chcesz zmienić status wypłaty?</h5>
                                        </Col>
                                        {this.state.payoutDetails.payoutStatus === 1 &&
                                            <Col xs={12} className='text-center py-2'>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={this.state.sendMail}
                                                    label="Wyij mail"
                                                    onChange={(e) => { this.setState({ sendMail: e.target.checked }) }}
                                                />


                                            </Col>
                                        }
                                        <Col xs={12} className='text-center py-2'>
                                            {this.state.payoutDetails.payoutStatus === 1 &&
                                                <Button variant={'success'}
                                                    onClick={() => { this.editPayoutStatus('setAsPaid') }}
                                                    disabled={this.state.payoutStatusFetching}
                                                >
                                                    OZNACZ JAKO ZAPŁACONĄ {this.state.payoutStatusFetching && <Spinner size={'sm'} />}
                                                </Button>
                                            }
                                            {this.state.payoutDetails.payoutStatus === 2 &&
                                                <Button variant={'danger'}
                                                    onClick={() => { this.editPayoutStatus('setAsUnpaid') }}
                                                    disabled={this.state.payoutStatusFetching}
                                                >
                                                    OZNACZ JAKO NIEZAPŁACONĄ {this.state.payoutStatusFetching && <Spinner size={'sm'} />}
                                                </Button>
                                            }
                                        </Col>
                                    </>
                                }
                                <Col xs={12} className='text-center py-2'>

                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                </Modal >
            </Container>
        )
    }

}




function mapStateToProps(state) {
    return {
        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getPayoutDetails,
                getPayoutsList,
                editPayoutDetails,
                getInvoicePdf
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutDetailsView);

