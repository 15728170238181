import React, { Component,} from "react";
import ReactPlayer from 'react-player'
import VisibilitySensor from "react-visibility-sensor";
import { AiOutlineWarning } from "react-icons/ai";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {isIOS} from "react-device-detect";
//import videoConversion from '../../public/logos/videoConversion.png';

export default class VideoPlayer extends Component {
    constructor(props) {
      super(props);
      this.contentEditable = React.createRef();
      this.playerRef2 = React.createRef()
    }
    componentDidMount() {
     // console.log('isIOS',isIOS)
  //   console.log('VideoPlayer props', this.props)
      //let posterUrl=process.env.PUBLIC_URL+'/videoConversion.png'
      let posterUrl='/blind.png'
      if(this.props.video.videoSpriteA){
        posterUrl=this.props.video.videoSpriteA
        if(this.props.sas){
          posterUrl=posterUrl+'?'+this.props.sas
        }
       // this.setState({posterUrl:posterUrl})
      }
      else{

        //podstawiamy domyslny poster z info o konwersji
      }
      this.setState({posterUrl:posterUrl})
      if(this.props.video.videoHls)
      {
        if(!isIOS){
          this.setState({forceHls:true})
        }
      }
      else{
        //podstawiamy domyslny poster
      }

      window.addEventListener('stopPlayers', e => {
            this.forceStopPlayer()
      })
    }

    componentWillUnmount() {
        window.removeEventListener('stopPlayers', e => {
            this.forceStopPlayer()
        })
    }

    state={
        playing:false,
        pip:false,
        forceHls:true,
        playerKey:'vidPlay'+(Math.random() + 1).toString(36).substring(7),
        posterUrl:null
    }

    forceStopPlayer=()=>{
        this.setState({pip:false})
        this.playerOnPause()
    }

    playerOnStart=(player)=>{
     //
    }
    onClickPreview=()=>{
      setTimeout(()=>{
        this.playerRef2.current.player.player.play()
      }, 200)
    }

    playerOnPlay=()=>{
     // console.log('playerOnPlay')
        const stopPlayersEvent = new Event('stopPlayers');
        window.dispatchEvent(stopPlayersEvent);
        this.setState({ playing: true })
    }
    playerOnPause=()=>{
        this.setState({ playing: false })
    }
    playerRefs=[]

    playerRef = player => {
        this.player = player
      }

    onVisibilityChange=(visible)=>{
        if(!visible){
          this.setState({playerKey:'vidPlay'+(Math.random() + 1).toString(36).substring(7)})
        }
      
        if(!visible && this.state.playing)
        {
            this.playerOnPause()
           // this.setState({pip:true})
        }
        if(visible && this.state.playing)
        {
           // this.setState({pip:false})
        }
      }


    render() {
        let url = this.props.video.videoname  
        let showWarning=true
        let forceHls=false
        if(this.props.video.videoHls){
          url=this.props.video.videoHls
          showWarning=false
          if(!isIOS){
            forceHls=true
          }
        }
        if(this.props.video.assetStreamHlsUrl){
          showWarning=false
          url=this.props.video.assetStreamHlsUrl
          if(!isIOS){
            forceHls=true
          }
        }
        console.log('url', url)
        return(
            <div className={"mb-2 videoPlayerCont "+this.props.addClass} key={this.props.video.videoname}>
                {showWarning&&
                  <OverlayTrigger
                    key={'placement'}
                    placement={'right'}
                    overlay={
                      <Tooltip id={'tooltip-placement'}>
                        <strong> To video jest w trakcie konwersji.</strong> Moze się jeszcze nie wyświetlać prawidłowo na niektórych urządzeniach. Odświerz stronę za kilka minut.
                      </Tooltip>
                    }
                  >
                    <div className="mediaConvertionWarning"><AiOutlineWarning style={{fontSize:'30px'}}/></div>
                  </OverlayTrigger>
                    
                }
                <VisibilitySensor 
                partialVisibility={true}
                offset={{top:150, bottom:50}}
                onChange={this.onVisibilityChange}> 
                <ReactPlayer
                    key={this.state.playerKey}
                    ref={this.playerRef2}
                    playing={this.state.playing}
                    pip={this.state.pip}
                    controls
                    width={'100%'}
                    height={'350px'}
                    onStart={()=>this.playerOnStart(this.player)}
                    onPlay={()=>this.playerOnPlay()}
                    onPause={()=>this.playerOnPause()}
                    light={this.state.posterUrl}
                    onClickPreview={()=>{this.onClickPreview()}}
                    config={{
                        file: {
                          forceHLS:forceHls,
                          hlsOptions: {
                            //capLevelToPlayerSize: true,
                            startLevel: 3,
                            maxStarvationDelay:3,
                            startFragPrefetch:true,
                           // enableWorker: true,
                          //  debug: true,
                          }, 
                          attributes: {
                            onContextMenu: e => e.preventDefault(),
                            controlsList: 'nodownload  ',
                            poster: this.state.posterUrl
                          }
                        }
                    }}
                    style={{backgroundColor:'#000', paddingBottom:'3px', cursor:'pointer'}}
                    url={url} />
        
            </VisibilitySensor>
            </div>
         )
        
    }
}