
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getDashboardData, getTransactionList } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import TransactionListModal from "../components/transactionListModal"
import PayoutsListModal from "../components/payoutsListModal"

import { Button } from "react-bootstrap";
class DashboardView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // this.props.getFavorites(this.favoritesFetched)
        this.startDataFetchTimer()

    }

    componentWillUnmount() {
    }

    state = {
    }

    startDataFetchTimer = () => {
        window.setInterval(
            () => {
                this.props.getDashboardData()
            }, 20000
        );
    }
    showVerificationList = () => {
        console.log('showVerificationList')
        this.setState({ redirect: '/verifications' })
    }


    transactionModalOnHide = () => {
        this.setState({ transactionModalShow: false, transactionListData: undefined })
    }

    getTransactionList = (day) => {
        console.log('getTransactionList', day)
        this.setState({ transactionListFetching: true, transactionListData: undefined })
        this.props.getTransactionList(day, this.transactionListFetched)
    }

    transactionListFetched = (data) => {
        console.log('transactionListFetched', data)
        this.setState({ transactionListFetching: false })
        if (!data.error) {
            this.setState({ transactionListData: data })
        }
    }

    showTodaysTransactionsList = () => {
        this.setState({ transactionModalShow: true })
    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        if (!this.props.dashboardData) {
            return (
                <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                    <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                </Container>
            )
        }
        return (
            <Col xs={12} className='p-0'>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>ZGŁOSZENIA</h3>
                    </Col>
                </Row>
                <Row noGutters>
                    <DashboardBox
                        xforceMd12={true}
                        title='Oczekujący na weryfikacje:'
                        value={this.props.dashboardData.awaitingCreators}
                        buttonText='Zobacz liste'
                        buttonAction={() => this.showVerificationList()}

                    />
                    <DashboardBox
                        xforceMd12={true}
                        title='Wypłaty Żetonów:'
                        value={this.props.dashboardData.pendingTokenPayouts}
                        buttonText='Przejdź do wypłat'
                        buttonAction={() => this.setState({ redirect: '/tokenpayouts/' })}

                    />
                </Row>
                <Row noGutters>



                </Row>
                <Row noGutters>
                    <DashboardBox
                        forceMd12={true}
                        title='Wypłaty:'
                        value={<button className='btn-red' onClick={() => { this.setState({ payoutListModalShow: true }) }}>
                            WYGENERUJ TYGODNIÓWKI
                        </button>
                        }
                        title2='Wygeneruj tygodniowe wypłaty dla twórców'
                    />
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>ŻETONY</h3>
                    </Col>
                </Row>
                <Row noGutters>
                <DashboardBox
                        //forceMd12={true}
                        title='Zysk dzisiaj:'
                        value={this.props.dashboardData.todaysTokensRevenue+' PLN'}
                        title2='Netto'
                        buttonAction={() => null}

                    />
                    <DashboardBox
                        //forceMd12={true}
                        title='Bierzący tydzień:'
                        value={this.props.dashboardData.weekTokensRevenue+' PLN'}
                        title2='Netto'
                        buttonAction={() => null}

                    />
                     <DashboardBox
                        //forceMd12={true}
                        title='Bierzący miesiąc:'
                        value={this.props.dashboardData.monthTokensRevenue+' PLN'}
                        title2='Netto'
                        buttonAction={() => null}

                    />
                     <DashboardBox
                        //forceMd12={true}
                        title='Bierzący rok:'
                        value={this.props.dashboardData.yearTokensRevenue+' PLN'}
                        title2='Netto'
                        buttonAction={() => null}

                    />
                    <DashboardBox
                        //forceMd12={true}
                        title='Wartość żetonów w depozycie:'
                        value={this.props.dashboardData.tokensAlive.fiatValue+' PLN'}
                        title2={'Ilość '+this.props.dashboardData.tokensAlive.amount+' Szt.'}
                        buttonAction={() => null}

                    />
                    <DashboardBox
                        //forceMd12={true}
                        title='Transfer Żetonów:'
                        value={this.props.dashboardData.todaysTransferedTokens+' SZT'}
                        title2={'Dziś'}
                        buttonText='Zobacz liste'
                        buttonAction={() => null}

                    />
                    
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>SPRZEDAŻ OGÓŁEM</h3>
                    </Col>
                </Row>
                <Row noGutters>

                    <DashboardBox
                        title='Dziś:'
                        value={this.props.dashboardData.todaysSale}
                        title2='Dzisiejsza sprzedaż'
                        buttonText='Zobacz liste'
                        buttonAction={() => this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący tydzień:'
                        value={this.props.dashboardData.thisWeekSale}
                        title2='Sprzedaż z bierzącego tygodnia'
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący miesiąc:'
                        value={this.props.dashboardData.thisMonthSale}
                        title2='Sprzedaż z bierzącego miesiąca'
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący rok:'
                        value={this.props.dashboardData.thisYearSale}
                        title2={'Sprzedaż od początku: ' + this.props.dashboardData.totalSale}
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>PROWIZJE  SERWISU</h3>
                    </Col>
                </Row>
                <Row noGutters>
                    <DashboardBox
                        title='Dziś:'
                        value={this.props.dashboardData.todaysRevenue}
                        title2='Dzisiejsza prowizja'
                        buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący tydzień:'
                        value={this.props.dashboardData.thisWeekRevenue}
                        title2='Prowija z bierzącego tygodnia'
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący miesiąc:'
                        value={this.props.dashboardData.thisMonthRevenue}
                        title2='Prowizja z bierzącego miesiąca'
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                    <DashboardBox
                        title='Bieżący rok:'
                        value={this.props.dashboardData.thisYearRevenue}
                        title2={'Prowizja od początku: ' + this.props.dashboardData.totalRevenue}
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}

                    />
                </Row>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>UŻYTKOWNICY</h3>
                    </Col>
                </Row>
                <Row noGutters>
                    <DashboardBox
                        forceMd12={true}
                        title='Łącznie:'
                        value={this.props.dashboardData.totalCreators + this.props.dashboardData.totalFans}
                        title2='Całkowita ilość użytkowników'
                    />
                    <DashboardBox
                        title='Fani:'
                        value={this.props.dashboardData.totalFans}
                        title2={'Całkowita ilość fanów'}
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}
                    />
                    <DashboardBox
                        title='Twórcy:'
                        value={this.props.dashboardData.totalCreators}
                        title2={'Całkowita ilość twórców'}
                    //  buttonText='Zobacz liste'
                    //  buttonAction={()=>this.showTodaysTransactionsList()}
                    />
                </Row>
                <UserSearchBox />
                <TransactionListModal
                    show={this.state.transactionModalShow}
                    onHide={() => this.transactionModalOnHide()}
                    fetch={(day) => this.getTransactionList(day)}
                    fetching={this.state.transactionListFetching}
                    data={this.state.transactionListData}
                />
                <PayoutsListModal
                    show={this.state.payoutListModalShow}
                    onHide={() => this.setState({ payoutListModalShow: false })}
                />
            </Col>
        )
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getDashboardData,
                getTransactionList,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardView);

