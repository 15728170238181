import React from "react";
import {Oval} from 'react-loader-spinner'

function LoaderAbsoluteOverlay(props) {
    let size=125
    let variant='red'
    if(props.size){
        size=props.size
    }
    if(props.variant){
        variant=props.variant
    }

    return (
        <div style={{position:'absolute', zIndex:'100', top:'0px', bottom:'0px', left:'0px', right:'0px', backgroundColor:'rgba(255,255,255,0.5)'}}>
            <div style={{width:size}} className='absolute-center'>
                <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={size} width={size} />
            </div>
        </div>
    )
}
export default LoaderAbsoluteOverlay