
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getBroadcastsTokens,
} from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";

import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import VerificationPicture from "../components/verificationPicture";
import ImageAnalizeModal from "../components/imageAnalizeModal";
import Header from "../components/userView/header";
import DjangoAdmin from "../components/userView/djangoAdmin";
import { BsClock } from 'react-icons/bs';
import { genders, profileStatus } from '../constants/common'
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import AdminPlayer from '../components/broadcast/adminPlayer'
import Cam2camPlayer from '../components/broadcast/cam2camPlayer'
import StreamChatWrapper from '../components/broadcast/streamChatWrapper'

import { HostUrl } from '../actions/authActions'
const ChatWsUrl = 'ws/admin/stream/chat/'
class StreamPlayerWrapper extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        let broadcastUUID = this.props.match.params.broadcastUUID
        this.setState({ broadcastUUID: broadcastUUID }, () => {
            this.fetchData()
        })

    }

    componentWillUnmount() {
    }

    state = {
        broadcastUUID: undefined,
        broadcastsData: undefined,
        chatContent: [],
    }

    fetchData = () => {
        this.setState({ fetching: true, })
        getBroadcastsTokens(this.state.broadcastUUID, this.dataFetched)
    }

    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            let newCameraTracks = []
            let currentTrack = {}
            if (data.tokens && Object.keys(data.tokens).length > 0) {
                Object.keys(data.tokens).forEach((trackId) => {
                    let cameraObj = { trackId: trackId, rtcProps: data.tokens[trackId] }
                    newCameraTracks.push(cameraObj)
                })
            }


            this.setState({ broadcastsData: data })
            this.setState({
                cam2cam: data.cam2cam,
                cameraTracks: newCameraTracks,
                // goalData: data.goal,
                broadcastId: data.channel,
                //  streamMode: data.streamMode,
                broadcastData: data.broadcastCache,
                privTimeSync: data.privTimeSync
            }, () => {
                this.chatWsConnect()
            })
            // this.setState({ broadcastsData: fakeData })
        }
        else {
            console.log(data)
            if (data.error.message) {
                alert(data.error.message)
            }
        }
    }


    chatWsConnect = () => {
        const channelName = this.state.broadcastUUID
        if (channelName) {
            let wsProtocol = 'wss'
            if (window.location.protocol === 'http:') {
                wsProtocol = 'ws'
            }
            let host = HostUrl + ChatWsUrl + '/?token=' + this.state.authToken + '&channelName=' + channelName
            const socket = new WebSocket(host);
            socket.onopen = this.onOpen;
            socket.onclose = this.onClose;
            socket.onerror = this.onError;
            socket.onmessage = this.onMessage;

        }
        else {
            console.log('streamChatWsMiddleware cant connect chat socket, no channelName in action.data')
        }
    }

    onOpen = (event) => {
        console.log('chatWs websocket open', event.target.url);
    };

    onClose = (e) => {
        console.log('chatWs onClose', e)
    };

    onError = (e) => {
        console.log('chatWs onError', e)
    };

    appendChatMessage = (data) => {
        this.setState({ chatContent: [...this.state.chatContent, data] }, () => {
            console.log('this.state.chatContent', this.state.chatContent)
        })
    }

    onMessage = (event) => {
        const payload = JSON.parse(event.data);
        console.log('adminStreamChatWs on message', payload)
        switch (payload.command) {
            case 'STREAM_CHAT_WS_USER_JOINED':
                this.appendChatMessage(payload.data)
                break;
            case 'STREAM_CHAT_WS_USER_LEFT':
                //wyswietlamy informacje o nowym LEAVIE. Dodajemy do chatData i wyswietlamy w chacie?
                this.appendChatMessage(payload.data)
                break;
            case 'STREAM_CHAT_WS_NEW_TIP_SENT':
                this.appendChatMessage(payload.data)
                break;
            case 'STREAM_CHAT_WS_NEW_MESSAGE':
                this.appendChatMessage(payload.data)
                break
            case 'STREAM_ADMIN_CHAT_CHAT_HISTORY':
                this.setState({ chatContent: payload.data })
                break

            case 'STREAM_CHAT_WS_CAMERA_ORIENTATION_CHANGED':
                //   const event = new CustomEvent("RemoteCameraOrientationChange");
                //  window.dispatchEvent(event);
                break
        }



    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }


        return (
            <Container fluid>
                <Row noGutters>
                    <Col xs={12} className="text-left">
                        {this.state.broadcastData &&
                            <Row>
                                <Col xs={12}>
                                    {this.state.broadcastData.broadcaster.displayName}
                                    {this.state.broadcastData.privUserData &&
                                        <span> Priv z {this.state.broadcastData.privUserData.displayName}</span>
                                    }
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                {!this.state.broadcastsData && this.state.fetching &&
                    <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                        <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                        </Container>
                    </div>
                }
                <Row noGutters>
                    <Col xs={12} lg={8}>
                        {this.state.broadcastsData && this.state.broadcastsData.tokens.map((item, index) => {
                            return <div className="streamWrapper">
                                <AdminPlayer rtcpProps={item} key={'playerKey' + index} />

                            </div>
                        })}
                        {this.state.cam2cam &&
                            <Cam2camPlayer channelName={this.state.cam2cam} broadcastData={this.state.broadcastData} />
                        }
                    </Col>
                    <Col xs={12} lg={4}>
                        czat
                        dodac cam2cam, mute audio, bany i ograniczenia
                        <StreamChatWrapper chatContent={this.state.chatContent}/>
                    </Col>
                </Row>
            </Container>


        )



    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,
        user: state.user.user,
        profile: state.user.profile,
        adminLogs: state.user.adminLogs,
        inWatched: state.user.inWatched,
        declineReasons: state.user.declineReasons,
        nextActions: state.user.nextActions,
        inSuspected: state.user.inSuspected,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {

            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamPlayerWrapper);

