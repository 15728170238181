import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
//import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from "./store/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropzone-uploader/dist/styles.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "yet-another-react-lightbox/dist/styles.css";
import './index.css';
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
  document.getElementById('root')
);