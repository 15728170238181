import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getPayoutData,
    generateInvoicePdf,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";

class PayoutDataBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        this.setState({ fetching: true })
        this.props.getPayoutData(this.props.user.id, this.dataFetched)
    }
    state = {
        fetching: true,
        iban:'',
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ data: data })
            if(this.state.data.iban){
                this.parseIban(this.state.data.iban)
            }
        }
    }

    parseIban = (iban) => {
        try {
            let ibanCountryCode = iban.slice(0, 2);
            let ibanNumber = iban.slice(2, iban.length);
            this.setState({ ibanCountryCode: ibanCountryCode })
            if (ibanCountryCode == 'PL') {
                this.setState({ iban: ibanNumber })
            }
            else {
                this.setState({ iban: iban })

            }
        } catch (e) {
            console.log('parseIban error', e)

            this.setState({ iban: 'Błąd! Brak nr IBAN', ibanCountryCode: '??' })
        }
        // ibanNumber = this.addSpacesToIban(ibanNumber)
    }

    generateInvoicePdf=(wyplataPk)=>{
        this.props.getInvoicePdf(wyplataPk, this.pdfGenerated)
    }

    pdfGenerated=(data)=>{
        console.log('pdfGenerated', data)
    }

    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='text-center pt-4 pb-3'>
                    <h4>PAYOUT DATA:</h4>
                </Col>
                <Col xs={12} className='border p-3 text-center'>
                        
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>

                            {this.state.data &&
                                <Row noGutters>
                                    <Col xs={12} className="upperCase textBlueGrey">
                                        <h4>
                                            {this.state.data.bankAccountName}
                                        </h4>
                                        <h4>
                                            {this.state.data.bankAccountSurname}
                                        </h4>
                                    </Col>
                                    <Col xs={12} className='upperCase text-muted'>
                                        {this.state.data.payoutChoice === 1 &&
                                            <span><strong>przelew na konto</strong></span>
                                        }
                                    </Col>
                                    <Col xs={12} className='textBlueGrey py-2'>
                                        <h4>
                                            {this.state.data.payoutChoice === 1 && 
                                                this.state.iban
                                            }
                                        </h4>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getPayoutData,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutDataBox);