
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getWatchedList, removeFromWatched } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";

class ObservedListView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getWatchedList(this.dataFetched)

    }

    componentWillUnmount() {
    }

    state = {
    }


    dataFetched = (data) => {
        console.log('dataFetched', data)
        if (!data.error) {
            this.setState({ data: data })
        }

    }

    removeFromList = (item) => {
        this.props.removeFromWatched(item.blogger.id, this.removeFetched)
    }

    removeFetched = (data) => {
        console.log('removeFetched', data)
        if (!data.error) {
            let oldData = [...this.state.data]
            let index = oldData.findIndex(x => x.blogger.id === data.id)
            console.log('jest index ', index)
            oldData.splice(index, 1)
            this.setState({ data: oldData })
        }

    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Col xs={12} className='p-0'>
                <Row noGutters>
                    <Col xs={12} className='text-center upperCase py-3'>
                        <h3>W obserwacji</h3>
                    </Col>
                </Row>
                {!this.state.data &&
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                }
                {this.state.data &&
                    <Row noGutters>
                        <Col xs={12}>
                            {this.state.data && this.state.data.length === 0 &&
                                <p>Lista jest pusta.</p>
                            }
                            {this.state.data && this.state.data.length > 0 &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" ></th>
                                            <th scope="col" className='text-center'>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((item, index) => {
                                            return (
                                                <tr key={'tiItem' + index} className='textBlueGrey capitalize '>
                                                    <td className="strong text-left">
                                                        <Link to={'/user/' + item.blogger.id + '/'} target={'_blank'}>
                                                            <Image roundedCircle height={30} src={item.blogger.avatar} />  {item.blogger.displayName}
                                                        </Link>
                                                    </td>
                                                    <td className=" text-left py-4">
                                                        {item.date}
                                                    </td>
                                                    <td className=" text-right py-4">
                                                        <Button onClick={() => this.removeFromList(item)}>Usuń z listy</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                        </Col>
                    </Row>
                }

            </Col>
        )
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getWatchedList,
                removeFromWatched
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ObservedListView);

